import React from 'react';
import styled from 'styled-components';
import Avatar from '@mui/material/Avatar';

export interface AvatarImageProps {
    userName?: any;
    size?: number;
    width?: number;
    height?: number;
};

const AvatarImage = ({ userName, size = 15, width = 30, height = 30 }: AvatarImageProps) => {

    const splitName = userName && userName.split(' ').map(function (s: any) { return s.charAt(0); }).join('').toUpperCase();

    return (
        <StyledAvatar fontSize={size} width={width} height={height}>{splitName}</StyledAvatar>
    )
};

//#region :: Styled Components
const StyledAvatar: any = styled(Avatar)`
    background-color: ${({ theme }) => theme.palette.primary.main + '!important'};
    width: ${({ width }: any) => width && width + 'px!important'};
    height: ${({ height }: any) => height && height + 'px!important'};
    margin-left: 5px !important;
    margin-right: 5px !important;
    font-size: ${({ fontSize }: any) => fontSize && fontSize + 'px!important'};
`;
//#endregion

export default AvatarImage;