export const PaginationEnums = {
    PAGE_SIZE: 10,
    SMALL_PAGE_SIZE: 5
};

export const Integer = {
    Max: 2147483647
};

export const StatusTypes = {
    ACTIVE: 1,
    IN_ACTIVE: 2
};

export const IdentityTypes = {
    NATIONAL_IDENTITY: 401,
    IQAMA_IDENTITY: 402,
    COMMERCIAL_REGISTRATION_NUMBER: 403,
    UNIFIED_ORGANIZATION_NUMBER: 404,
    CITIZENS_OF_GULF_COUNTRIES: 405,
    PASSPORT: 406,
    BORDER_NUMBER: 407,
    FAMILY_CARD_NUMBER: 408,
    C700: 409
};

export enum ORGANIZATION_NUMBER {
    Departments = 1,
    Units = 2,
    Sections = 3,
    Revenue_Streams = 4,
    Sub_Revenue_Streams = 5,
    Projects = 6,
    Project_Main_Activities = 7,
};

export const PersonalInformationSubCategories = {
    TYPE: 1,
    NATIONALITY: 2,
    RELIGION: 3,
    EMPLOYEE_STATUS: 4,
    BLOOD_TYPE: 5,
    MARTIAL_STATUS: 6,
    HEALTH_STATUS: 7
};

export const UserType = {
    SYSTEM_ADMIN: 'System Admin',
    SYSTEM_ADMIN_MANAGER: 'System Admin and Manager',
    MANAGER: 'Manager',
    EMPLOYEE: 'Employee'
};


export const DAILY_WORKING_STATUS = {
    NOACTION: 0,
    SAVED: 1,
    SUBMITED: 2,
    APPROVED: 3,
    REJECTED: 4,
    CLOSED: 5,
    EDITED_APPROVED: 6,
    EDITED_SUBMITED: 7
};

export const EMPLOYEE_STATUS = {
    ACTIVE: 1,
    INACTIVE: 2
};

export const MAIN_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0
};