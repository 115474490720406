import { StorageService, STORAGE_KEY } from "./storage.service";

export class GlobalService {


    public static get AppUser(): User | null {
        let storageItem = StorageService.getItem(STORAGE_KEY.CURRENT_USER);
        if (storageItem && storageItem.body)
            return storageItem.body;
        return null;
    }

}



export interface User {

    id: number;
    name: number;
    isSystemAdmin: boolean;
    isManager: boolean;

}