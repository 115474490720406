import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from '../Layout/Auth';

const SignIn = lazy(() => import('../Pages/Auth/SignIn'));
const SignUp = lazy(() => import('../Pages/Auth/SignUp'));
const ForgotPassword = lazy(() => import('../Pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../Pages/Auth/ResetPassword'));

const NotFound = () => {
  return <Redirect to="/" />;
};

const FrontendRoutes = () => {
  return (
    <Switch>
      <Route exact path="/forgotPassword" component={ForgotPassword} />
      <Route exact path="/reset_password" component={ResetPassword} />

      <Route exact path="/register" component={SignUp} />
      <Route exact path="/" component={SignIn} />
      <Route exact path="*" component={NotFound} />
    </Switch>
  );
};

export default Layout(FrontendRoutes);