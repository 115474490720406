import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetEDTDateFromDateTimeNullable } from "../../../Utils/DateUtils";

const reports = createSlice({
    name: "reports",
    initialState: {
        ListOfReports: {},
        keys: [],
        ReportDetails: {},
        searchCriteria: {
            startDate: null,
            endDate: null,
            employeeId: null,
            revenueStreamId: null,
            projectId: null,
            organizationId: null,
            chargeCodeId: null,
            chargeCodeDepId: null
        },
        showError: false,
        error: null
    },
    reducers: {
        reportsRecived: (reports: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {
                reports.ListOfReports[item.id] = item;
                reports.keys = [...reports.keys, item.id]
            });

            reports.ReportDetails = {};
        },

        reportUpdated: (reports: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;

            reports.ListOfReports[data.id] = { ...reports.ListOfReports[data.id], isActive: !data.isActive };
            reports.keys = [...reports.keys, data.id];
        },

        setReport: (reports: any, { payload }: PayloadAction<any>) => {
            reports.reportDetails = payload.data;
        },
        setError: (reports: any, { payload }: PayloadAction<any>) => {
            reports.error = payload.response;
            reports.showError = true;
        },

        setSearchCriteria: (reports: any, { payload }: PayloadAction<any>) => {
            const { startDate, endDate, employeeId, revenueStreamId, projectId, organizationId, chargeCodeId, chargeCodeDepId } = payload.body;

            reports.searchCriteria = {
                startDate: GetEDTDateFromDateTimeNullable(startDate),
                endDate: GetEDTDateFromDateTimeNullable(endDate),
                employeeId: employeeId,
                revenueStreamId: revenueStreamId,
                projectId: projectId,
                organizationId: organizationId,
                chargeCodeId: chargeCodeId,
                chargeCodeDepId: chargeCodeDepId
            };
        },
        resetSearchCriteria: (reports: any) => {
            reports.searchCriteria = {
                startDate: null,
                endDate: null,
                employeeId: null,
                revenueStreamId: null,
                projectId: null,
                organizationId: null,
                chargeCodeId: null,
                chargeCodeDepId: null
            };
        }
    }
});

export const {
    reportsRecived,
    setReport,
    setError,
    setSearchCriteria,
    resetSearchCriteria,
    reportUpdated
} = reports.actions;

export default reports.reducer;

//#region :: Selectors
export const getReportsData = (state: any) => {
    return state.reports.ListOfReports;
};

export const getSearchCriteria = (state: any) => {
    return state.reports.searchCriteria;
};
export const getError = (state: any) => {
    return state.reports.error;
};
export const getchargeCodesKeys = (state: any) => {
    return state.reports.keys;
};

export const getReportDetails = (state: any) => {
    return state.reports.ReportDetails;
};
export const getShowError = (state: any) => {
    return state.reports.showError;
};

export const reportsSelectors = {
    getSearchCriteria,
    getReportDetails,
    getReportsData,
    getError,
    getShowError,
    getchargeCodesKeys
};
//#endregion