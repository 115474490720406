import API from './API';
import Logger from './Logger';
import Pagination from './Pagination';

const Middleware = (getDefaultMiddleware: any) => {
    return getDefaultMiddleware()
        .concat(Logger)
        .concat(Pagination)
        .concat(API)
};

export default Middleware;