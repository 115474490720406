import { theme as MainThemeVariables } from './ThemeVariables';

const MuiThemeVariables: any = {
    status: {
        white: MainThemeVariables['white-color'],
        dark: MainThemeVariables['dark-color'],
        transparent: MainThemeVariables['transparent-color'],
        lightGray: MainThemeVariables['light-gray-color'],
    },
    palette: {
        primary: {
            main: MainThemeVariables['primary-color'],
            whiteColor: MainThemeVariables['white-color'],
            whiteSmoke: MainThemeVariables['white-smoke'],
            greenColor: MainThemeVariables['green-color'],
            black: MainThemeVariables['black-color'],
            primaryLightColor: MainThemeVariables['primary-light-color'],
            Chestnut: MainThemeVariables['Chestnut'],
            Comet: MainThemeVariables['Comet'],
            Gray46: MainThemeVariables['gray46-color'],
            Gray80: MainThemeVariables['gray80-color'],
            HawkesBlue: MainThemeVariables['Hawkes-blue'],
            BlackRussian: MainThemeVariables['Black-russian'],
            Gray98: MainThemeVariables['gray98-color'],
            Gray93: MainThemeVariables['gray93-color'],
            Gray99: MainThemeVariables['gray99-color'],
            SantasGrey: MainThemeVariables['Santas-grey'],
            SuvaGrey: MainThemeVariables['Suva-grey'],
            DimGray: MainThemeVariables['Dim-gray'],
            Charcoal: MainThemeVariables['Charcoal'],
            Solitude: MainThemeVariables['Solitude'],
            blackTrans: MainThemeVariables['black-trans'],
            pattensBlue: MainThemeVariables['pattens-Blue'],
            silver: MainThemeVariables['silver'],
            gainsboro: MainThemeVariables['gainsboro'],
            vividPink: MainThemeVariables['vivid-pink'],
            darkGrayish: MainThemeVariables['dark-grayish'],
            dangerHover: MainThemeVariables['danger-hover'],
            grayTrans: MainThemeVariables['gray-trans'],
            KellyGreen: MainThemeVariables['Kelly-green'],
            IrisBlue: MainThemeVariables['Iris-blue'],
            SpeechBlue: MainThemeVariables['Speech-blue'],
            SpeechRed: MainThemeVariables['Speech-red'],
            ChateauGreen: MainThemeVariables['Chateau-green'],
            accordionHeadersTextColor: MainThemeVariables['accordion-headers-text-color'],

        },
    },
    typography: {
        fontFamily: 'Cairo',
        fontWeightLight: 200,
        fontWeightRegular: 400,
        fontWeightMedium: 300,
        fontWeightBold: 600
    },
    iconSize: {
        // sm: {
        //     main: MainThemeVariables['icon-sm']
        // }
    },
    // overrides: {
    //     MuiTableCell: {
    //         head: {
    //             color: MainThemeVariables['white-color']
    //         }
    //     }
    // },
    components: {
        //MuiTextField: {
        // defaultProps: {
        // InputLabelProps: {
        //     shrink: true
        // }
        // }
        //},
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true,
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: MainThemeVariables['white-color']
                }
            }
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            xsMobile: 309,
            MobileScreen: 501,
            sm: 600,
            TabScreen: 768,
            md: 960,
            lg: 1280,
            xl: 1920,
            k4: 2500
        },
    }
};

export { MuiThemeVariables };