const primaryColor = '#2e49a2';
const Gray46 = '#757575';
const Gray80 = '#959595';
const Solitude = '#ECEFF3';
const Comet = '#5A5E70';
const Chestnut = '#B95050';
const HawkesBlue = '#d2d4dd';
const Gray98 = '#fafafa';
const Gray93 = '#efeeee';
const Gray99 = '#727272';
const BlackRussian = '#0000000a';
const SuvaGrey = '#919191';
const DimGray = '#636363';
const Charcoal = '#454545';
const blackTrans = '#000000de';
const grayTrans = '#0000003b';
const silver = '#C3C3C3';
const vividPink = '#F50057';
const gainsboro = '#E0E0E0';
const pattensBlue = '#ced6ff';
const darkGrayish = '#282525';
const primaryLightColor = '#697DBD';
const primaryHover = '#4347D9';
const secondaryColor = '#FF69A5';
const secondaryHover = '#E34A87';
const transparent = 'transparent';
const linkColor = '#1890ff';
const linkHover = '#0D79DF';
const headingColor = 'rgba(0, 0, 0, 0.85)';
const successColor = '#20C997';
const successHover = '#0CAB7C';
const warningColor = '#FA8B0C';
const warningHover = '#D47407';
const errorColor = '#f5222d';
const errorHover = '#E30D0F';
const infoColor = '#2C99FF';
const infoHover = '#0D79DF';
const darkColor = '#272B41';
const darkHover = '#131623';
const grayColor = '#5A5F7D';
const grayHover = '#363A51';
const lightColor = '#9299B8';
const lightHover = '#e2e6ea';
const whiteColor = '#ffffff';
const dashColor = '#E3E6EF';
const whiteSmoke = '#F3F3F3';
const whiteHover = '#5A5F7D';
const extraLightColor = '#ADB4D2';
const dangerColor = '#FF4D4F';
const dangerHover = '#E30D0F';
const borderColorLight = '#F1F2F6';
const borderColorNormal = '#E3E6EF';
const borderColorDeep = '#C6D0DC';
const bgGrayColorDeep = '#EFF0F3';
const bgGrayColorLight = '#F8F9FB';
const bgGrayColorNormal = '#F4F5F7';
const SantasGrey = '#9999A9';
const lightGrayColor = '#C8C8C8';
const iconsGrayColor = '#0000008a';
const sliderRailColor = 'rgba(95,99,242,0.2)';
const graySolid = '#9299b8';
const pinkColor = '#F63178';
const greenColor = '#28a745';
const ChateauGreen = '#28A745';
const KellyGreen = '#4DCE18';
const IrisBlue = '#15A7CD';
const lightBlueColor = '#9db5da';
const skipBtnColor = '#17a2b8';
const iconExport = '#27b1a2';
const SpeechBlue = '#4A64CB';
const SpeechRed = '#CA0B00';
const blackColor = '#000';
const accordionHeadersBgColor = '#ced6ff';
const accordionHeadersTextColor = '#2366ab';

const btnlg = '48px';
const btnsm = '36px';
const btnxs = '29px';

const theme = {
  'primary-color': primaryColor, // primary color for all components
  'gray46-color': Gray46, //  color for all components #959595
  'Comet': Comet, //  color for all components #5A5E70
  'Chestnut': Chestnut, //  color for all components #B95050
  'gray80-color': Gray80, //  color for all components #959595
  'Solitude': Solitude, //  color for all components #ECEFF3
  'Hawkes-blue': HawkesBlue, //  color for all components #D2D4DD
  'Speech-blue': SpeechBlue, //  color for all components #4A64CB
  'Speech-red': SpeechRed, //  color for all components #CA0B00
  'gray98-color': Gray98, //  color for all components #fafafa
  'gray93-color': Gray93, //  color for all components #efeeee
  'gray99-color': Gray99, //  color for all components #727272
  'Santas-grey': SantasGrey, //  color for all components #9999A9
  'Black-russian': BlackRussian, //  color for all components #0000000a
  'Suva-grey': SuvaGrey, //  color for all components #727272
  'Dim-gray': DimGray, //  color for all components #636363
  'Charcoal': Charcoal, //  color for all components #454545
  'black-trans': blackTrans, //  color for all components #000000de
  'gray-trans': grayTrans, //  color for all components #0000003b
  'pattens-Blue': pattensBlue, //  color for all components #ced6ff 
  'silver': silver, //  color for all components #C3C3C3 
  'gainsboro': gainsboro, //  color for all components #E0E0E0 
  'vivid-pink': vividPink, // color for all components #f50057 
  'dark-grayish': darkGrayish, // color for all components #282525 
  'primary-light-color': primaryLightColor, // primary color for all components
  'primary-hover': primaryHover, // primary color for all components
  'secondary-color': secondaryColor, // secondary color for all components
  'secondary-hover': secondaryHover, // secondary color for all components
  'transparent-color': transparent, // transparent color for all components
  'link-color': linkColor, // link color
  'link-hover': linkHover, // link color
  'success-color': successColor, // success state color
  'success-hover': successHover, // success state color
  'warning-color': warningColor, // warning state color
  'warning-hover': warningHover, // warning state color
  'error-color': errorColor, // error state color
  'error-hover': errorHover, // error state color
  'info-color': infoColor, // info state color
  'info-hover': infoHover, // info state color
  'dark-color': darkColor, // info state color
  'dark-hover': darkHover, // info state color
  'gray-color': grayColor, // info state color
  'gray-hover': grayHover, // info state color
  'light-color': lightColor, // info state color
  'light-hover': lightHover, // info state color
  'white-color': whiteColor, // info state color
  'white-hover': whiteHover, // info state color
  'white-smoke': whiteSmoke, // info state color #F3F3F3
  'green-color': greenColor, // info state color
  'Chateau-green': ChateauGreen, // info state color #28A745
  'Kelly-green': KellyGreen, // info state color #4DCE18
  'Iris-blue': IrisBlue, // info state color #15A7CD
  'light-blue-color': lightBlueColor, // info state color
  'icon-gray-color': iconsGrayColor, // info state color
  'export-color': iconExport,
  'black-color': blackColor,
  'accordion-headers-color': accordionHeadersBgColor,
  'accordion-headers-text-color': accordionHeadersTextColor,
  white: whiteColor,
  pink: pinkColor,
  'dash-color': dashColor, // info state color
  'extra-light-color': extraLightColor, // info state color
  'danger-color': dangerColor,
  'danger-hover': dangerHover,
  'font-family': "'Inter', sans-serif",
  'font-size-base': '14px', // major text font size
  'heading-color': headingColor, // heading text color
  'text-color': darkColor, // major text color
  'text-color-secondary': grayColor, // secondary text color
  'disabled-color': 'rgba(0, 0, 0, 0.25)', // disable state color
  'border-radius-base': '4px', // major border radius
  'border-color-base': '#d9d9d9', // major border color
  'box-shadow-base': '0 2px 8px rgba(0, 0, 0, 0.15)', // major shadow for layers
  'border-color-light': borderColorLight,
  'border-color-normal': borderColorNormal,
  'border-color-deep': borderColorDeep,
  'bg-color-light': bgGrayColorLight,
  'bg-color-normal': bgGrayColorNormal,
  'bg-color-deep': bgGrayColorDeep,
  'light-gray-color': lightGrayColor,
  'gray-solid': graySolid,
  'btn-height-large': btnlg,
  'btn-height-small': btnsm,
  'btn-height-extra-small': btnxs,
  'btn-default-color': darkColor,
  'btn-skip-color': skipBtnColor,

  // cards
  'card-head-background': '#ffffff',
  'card-head-color': darkColor,
  'card-background': '#ffffff',
  'card-head-padding': '16px',
  'card-padding-base': '12px',
  'card-radius': '10px',
  'card-shadow': '0 5px 20px rgba(146,153,184,0.03)',

  // Layout
  'layout-body-background': '#F4F5F7',
  'layout-header-background': '#ffffff',
  'layout-footer-background': '#fafafa',
  'layout-header-height': '64px',
  'layout-header-padding': '0 15px',
  'layout-footer-padding': '24px 15px',
  'layout-sider-background': '#ffffff',
  'layout-trigger-height': '48px',
  'layout-trigger-background': '#002140',
  'layout-trigger-color': '#fff',
  'layout-zero-trigger-width': '36px',
  'layout-zero-trigger-height': '42px',
  // Layout light theme
  'layout-sider-background-light': '#fff',
  'layout-trigger-background-light': '#fff',
  'layout-trigger-color-light': 'rgba(0, 0, 0, 0.65)',

  // PageHeader
  // ---
  'page-header-padding': '24px',
  'page-header-padding-vertical': '16px',
  'page-header-padding-breadcrumb': '12px',
  'page-header-back-color': '#000',
  'page-header-ghost-bg': 'inherit',

  // Popover body background color
  'popover-color': darkColor,

  // alert
  'alert-success-border-color': successColor,
  'alert-success-bg-color': successColor + 15,
  'alert-error-bg-color': errorColor + 15,
  'alert-warning-bg-color': warningColor + 15,
  'alert-info-bg-color': infoColor + 15,

  // radio btn
  'radio-button-checked-bg': primaryColor,

  // gutter width
  'grid-gutter-width': 25,

  // skeleton
  'skeleton-color': borderColorLight,

  // slider
  'slider-rail-background-color': sliderRailColor,
  'slider-rail-background-color-hover': sliderRailColor,
  'slider-track-background-color': primaryColor,
  'slider-track-background-color-hover': primaryColor,
  'slider-handle-color': primaryColor,
  'slider-handle-size': '16px',

  // input
  'input-height-base': '48px',
  'input-border-color': borderColorNormal,
  'input-height-sm': '30px',
  'input-height-lg': '50px',

  // rate
  'rate-star-color': warningColor,
  'rate-star-size': '13px',

  // Switch
  'switch-min-width': '35px',
  'switch-sm-min-width': '30px',
  'switch-height': '18px',
  'switch-sm-height': '15px',

  // result
  'result-title-font-size': '20px',
  'result-subtitle-font-size': '12px',
  'result-icon-font-size': '50px',

  // tabs
  'tabs-horizontal-padding': '12px 15px',
  'tabs-horizontal-margin': '0',

  // list
  'list-item-padding': '10px 24px',

  // Tags
  'tag-default-bg': '#EFF0F3',
  'tag-default-color': darkColor,
  'tag-font-size': '11px',
};

const darkTheme = {
  ...theme,
  'primary-color': '#f00',
  backgroundColor: '#000',
};

//icon size
export { theme, darkTheme };
