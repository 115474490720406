import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const employeeTimesheetManagement = createSlice({
    name: "employeeTimesheetManagement",
    initialState: {
        employeeTimesheet: {},
        valid: true,
        keys: [],
        timeSheetDetails: {},
        searchCriteria: {
            Employee: null,
            IsActive: null
        },
        showError: false,
        error: null,
        weekPickerDate: new Date(),
        selectedEmployee: null,
        employeeTimesheetTotalHour: 0
    },
    reducers: {
        employeeTimeSheetRecived: (employeeTimesheetManagement: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {
                employeeTimesheetManagement.employeeTimesheet[item.Id] = item;
                employeeTimesheetManagement.keys = [...employeeTimesheetManagement.keys, item.Id];
            });
        },
        deleteTimeSheetById: (employeeTimesheetManagement: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            const timeSheetId = payload.timeSheetId;
            const filteredkeys = employeeTimesheetManagement.keys.filter((x: any) => x != timeSheetId);

            employeeTimesheetManagement.keys = filteredkeys;
        },
        setSearchCriteria: (employeeTimesheetManagement: any, { payload }: PayloadAction<any>) => {
            console.log(payload);
            const data = payload.response;
            employeeTimesheetManagement.searchCriteria = data;
        },
        setValidTime: (employeeTimesheetManagement: any, { payload }: PayloadAction<any>) => {
            console.log("payload ", payload.valid);
            employeeTimesheetManagement.valid = payload.valid;
        },
        setTodayTime: (employeeTimesheetManagement: any, { payload }: PayloadAction<any>) => {
            console.log("payload ", payload.date);
            employeeTimesheetManagement.todayDate = payload.date;
        },
        setError: (employeeTimesheetManagement: any, { payload }: PayloadAction<any>) => {
            employeeTimesheetManagement.error = payload.response;
            employeeTimesheetManagement.showError = true;
        },
        setWeekPickerDate: (employeeTimesheetManagement: any, { payload }: PayloadAction<any>) => {
            console.log("payload week picker", payload.date);
            employeeTimesheetManagement.weekPickerDate = payload.date;
        },
        setSelectedEmployee: (employeeTimesheetManagement: any, { payload }: PayloadAction<any>) => {
            employeeTimesheetManagement.selectedEmployee = payload.employee;
        },
        setEmployeeTimesheetTotalHour: (employeeTimesheetManagement: any, { payload }: PayloadAction<any>) => {
            employeeTimesheetManagement.employeeTimesheetTotalHour = payload.total;
        }
    }
});

export const {
    employeeTimeSheetRecived,
    setSearchCriteria,
    deleteTimeSheetById,
    setError,
    setTodayTime,
    setValidTime,
    setWeekPickerDate,
    setSelectedEmployee,
    setEmployeeTimesheetTotalHour
} = employeeTimesheetManagement.actions;

export default employeeTimesheetManagement.reducer;

//#region :: Selectors
export const getEmployeeTimeSheet = (state: any) => {
    return state.employeeTimesheetManagement.employeeTimesheet;
};

export const getEmployeeTimeSheetTodayDate = (state: any) => {
    return state.employeeTimesheetManagement.todayDate;
};

export const getEmployeeTimeSheetValidation = (state: any) => {
    return state.employeeTimesheetManagement.valid;
};

export const getEmployeeTimeSheetKeys = (state: any) => {
    return state.employeeTimesheetManagement.keys;
};

export const getSearchCriteria = (state: any) => {
    return state.employeeTimesheetManagement.searchCriteria;
};

export const getShowError = (state: any) => {
    return state.employeeTimesheetManagement.showError;
};

export const getError = (state: any) => {
    return state.employeeTimesheetManagement.error;
};

export const getWeekPickerDate = (state: any) => {
    return state.employeeTimesheetManagement.weekPickerDate;
};

export const getSelectedEmployee = (state: any) => {
    return state.employeeTimesheetManagement.selectedEmployee;
};
export const getEmployeeTimesheetTotalHour = (state: any) => {
    return state.employeeTimesheetManagement.employeeTimesheetTotalHour;
};

export const employeeTimesheetManagementSelectors = {
    getEmployeeTimeSheet,
    getEmployeeTimeSheetTodayDate,
    getEmployeeTimeSheetValidation,
    getEmployeeTimeSheetKeys,
    getSearchCriteria,
    getShowError,
    getError,
    getWeekPickerDate,
    getSelectedEmployee,
    getEmployeeTimesheetTotalHour
};
//#endregion