const CookiesConstants = {
    AUTH: {
        IS_LOGGED_IN: 'isLoggedIn',
        ROLE: 'role'
    },
    SETTINGS: {
        LANG: 'lang'
    }
};

export default CookiesConstants;