const getCookie = (cname: string) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            const targetCookie = c.substring(name.length, c.length);

            try {
                return JSON.parse(targetCookie);
            } catch (err) {
                return targetCookie;
            }
        }
    }
    return "";
};

const setCookie = (cname: string, cvalue: any, exdays?: any) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();

    const value = typeof cvalue !== 'string' ? JSON.stringify(cvalue) : cvalue;

    document.cookie = cname + "=" + value + ";" + expires + ";path=/";
}

const checkCookie = (name: string) => getCookie(name) !== undefined;

export { getCookie, setCookie, checkCookie }