import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUILanguage, languageChanged } from '../Store/Reducers/UI';
import Locales from '../Services/Internationalization/Locales';
import MasterWarper from './MasterWarper';
import Footer from './MasterPage/Footer';
import Container from '../Controls/Container';

const MasterLayout = (Content: any) => {

    const Layout = (props: any) => {
        return (
            <>
                <MasterWarper>
                    <Container>
                        <Content {...props} />
                    </Container>
                    <Footer />
                </MasterWarper>
            </>
        );
    };

    //#region :: Map State To Component
    const mapStateToProps = (state: any) => {
        return {
            language: getUILanguage(state)
        };
    };

    const mapStateToDispatch = (dispatch: any) => {
        return {
            changeLanguage: (lang: any) => dispatch(languageChanged({ language: lang }))
        };
    };
    //#endregion

    //#region :: Validation PropTypes
    Layout.propTypes = {
        language: propTypes.oneOf(Object.keys(Locales).map((k: any) => Locales[k])),
        changeLanguage: propTypes.func
    };
    //#endregion

    return connect(mapStateToProps, mapStateToDispatch)(Layout);
};

export default MasterLayout;