import { format } from 'date-fns';

const Months = {
    JAN: 'month.Jan',
    FEB: 'month.Feb',
    MAR: 'month.Mar',
    APR: 'month.Apr',
    MAY: 'month.May',
    JUN: 'month.Jun',
    JUL: 'month.Jul',
    AUG: 'month.Aug',
    SEP: 'month.Sep',
    OCT: 'month.Oct',
    NOV: 'month.Nov',
    DEC: 'month.Dec'
};

const FormatDate = (date: any) => {
    if (date !== undefined) {
        const newDate = new Date(date);
        const convertedDate = format(newDate, 'dd/MM/yyyy');

        return convertedDate;
    }
    else {
        return date;
    }
};

const FormatDateOnly = (date: any, formatDate = "dd/MM/yyyy") => {
    if (date !== undefined) {
        const newDate = new Date(date);
        const convertedDate = format(newDate, formatDate);

        return convertedDate;
    }
    else {
        return date;
    }
};

const FormatDateTime = (dateTime: any) => {
    if (dateTime !== undefined) {
        const newDateTime = new Date(dateTime);
        const convertedDateTime = format(newDateTime, 'dd/MM/yyyy hh:mm a');

        return convertedDateTime;
    }
    else {
        return dateTime;
    }
};

const TodayDate = () => {
    let today: any = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;

    return today;
};

const GetMonth = (month: any) => {
    switch (month) {
        case 1:
            return Months.JAN;
        case 2:
            return Months.FEB;
        case 3:
            return Months.MAR;
        case 4:
            return Months.APR;
        case 5:
            return Months.MAY;
        case 6:
            return Months.JUN;
        case 7:
            return Months.JUL;
        case 8:
            return Months.AUG;
        case 9:
            return Months.SEP;
        case 10:
            return Months.OCT;
        case 11:
            return Months.NOV;
        case 12:
            return Months.DEC;
        default:
            return month;
    }
};

const SplitMonthDate = (date: any) => {
    const array = date.split("-", 2);
    const month = GetMonth(parseInt(array[1]));

    return month;
};

const TimeZone = () => {
    return new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" }).split(",");
};

const getAge = (dateString: string) => {
    if (!dateString) return "ــــ";
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

const GetWeekNumberFromDate = (currentdate: any) => {
    return Math.ceil(currentdate.getDate() / 7)
};

const GetFirstDayOfFirstWeekOfMonthFromDate = (currentdate: Date) => {
    let firstDate = new Date(currentdate.getFullYear(), currentdate.getMonth(), 1);
    var day = firstDate.getDay(),
        diff = firstDate.getDate() - day + (day == 0 ? -6 : 0); // adjust when day is sunday

    let startDate = new Date(firstDate.setDate(diff));
    return startDate;
};

const GetLastDayOfLasttWeekOfMonthFromDate = (currentdate: Date) => {
    let firstDate = GetFirstDayOfFirstWeekOfMonthFromDate(currentdate);
    let endDate = new Date(firstDate);
    endDate.setDate(endDate.getDate() + 41);

    return endDate;
};

const ConvertMintusNumberToHoursMintesString = (num: number) => {
    if (!num) return "00:00";

    var hours = Math.floor(num / 60);
    var minutes = Math.floor(num % 60);
    var total = ((hours <= 9 ? '0' : '') + hours) + ":" + ((minutes <= 9 ? '0' : '') + minutes);

    return total;
};

const ConvertMintusNumberToHoursMintesFloat = (num: number) => {
    let total = ConvertMintusNumberToHoursMintesString(num);
    let result = total.replaceAll(':', '.');
    return parseFloat(result);
};

const ConvertHHMMtoMinutesValue = (timeString: string) => {
    if (timeString == '' || timeString == undefined)
        return 0;
    const hours = parseInt(timeString.substring(0, 2));
    const minutes = parseInt(timeString.substring(3, 5));
    let total = (hours * 60) + minutes;

    return total;
};

const GetEDTDateFromDateTime = (currentdate: Date) => {
    currentdate = new Date(currentdate);
    let startDateStr = currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1) + '-' + currentdate.getDate() + " EDT";
    currentdate = new Date(startDateStr.replace(/-/g, '\/'));
    return currentdate;
}

const GetEDTDateFromDateTimeNullable = (currentdate: Date) => {
    if (currentdate == undefined)
        return null;

    currentdate = new Date(currentdate);
    let startDateStr = currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1) + '-' + currentdate.getDate() + " EDT";
    currentdate = new Date(startDateStr.replace(/-/g, '\/'));
    return currentdate;
}

export {
    GetLastDayOfLasttWeekOfMonthFromDate,
    GetFirstDayOfFirstWeekOfMonthFromDate,
    ConvertHHMMtoMinutesValue,
    FormatDate,
    FormatDateOnly,
    FormatDateTime,
    TodayDate,
    SplitMonthDate,
    TimeZone,
    getAge,
    ConvertMintusNumberToHoursMintesString,
    ConvertMintusNumberToHoursMintesFloat,
    GetWeekNumberFromDate,
    GetEDTDateFromDateTime,
    GetEDTDateFromDateTimeNullable
};

