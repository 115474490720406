import { configureStore } from '@reduxjs/toolkit';
import reducer from './Reducer';
import middleware from './Middleware';

const ConfigureStore = () => {
    return configureStore({
        reducer,
        middleware
    });
};

export default ConfigureStore;
