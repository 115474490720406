const Env_Process = {
    DEVELOPMENT: 'development',
    STAGING: 'staging',
    PRODUCTION: 'production',
    DEMO: 'demo'
};

const GetBaseUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case Env_Process.DEVELOPMENT:
            return process.env.REACT_APP_DEV_BACKEND_BASE_URL;
        case Env_Process.STAGING:
            return process.env.REACT_APP_STAGING_BACKEND_BASE_URL;
        case Env_Process.PRODUCTION:
            return process.env.REACT_APP_PROD_BACKEND_BASE_URL;
        case Env_Process.DEMO:
            return process.env.REACT_APP_DEMO_BACKEND_BASE_URL;
    }
};

const api_base_url = `${GetBaseUrl()}`;

const APIConstants = {
    BASE_URL: api_base_url,
    IS_TEST_MODE: false,
    LOCALERESOURCES: 'LocaleResources',
    CUSTOMERS: {
        GETALL: 'Customers'
    },
    AUTH: {
        LOGIN: 'Accounts/Login',
        FORGOT_PASSWORD: 'Accounts/ForgotPassword',
        RESET_PASSWORD: 'Accounts/ForgotPasswordReset',
        CHANGE_PASSWORD: 'Accounts/ChangePassword',
        GET_SERVER_TIME: 'Accounts/GetServerTime',
        UNLOCK_USER: 'Accounts/UnlockUser',

    },
    EMPLOYEES: {
        GETALL: 'Employees',
        GET: 'Employees/',
        CREATE: 'Employees',
        EMPLOYEE_INFO: "EmployeeInfo/",
        EMPLOYEE: "Employees/",
        DELTE_EMP: "Employees/",
        SEARCH_EMPLOYEE: "Employees/Search",
        UPDATE: 'Employees/'
    },
    REPORTS: {
        GET: 'Reports',
        GENERAL_REPROT: 'Reports/GeneralReport',
        COST_REPROT: 'Reports/CostReport',
        SEARCH: 'Reports/Search',
        EMPLOYEESTRANSACTIONS: 'Reports/EmployeesTransactions',
    },
    PROJECTS: {
        GETALL: 'Projects',
        UPDATE: 'Projects/',
        CREATE: 'Projects',
        SEARCH_PROJECT: 'Projects/Search',
        DELETE_PROJECT: "Projects/",
        GET_LOOKUP: 'Projects/GetLookup'
    },
    REVENUESTREAMS: {
        GETALL: 'RevenueStreams',
        UPDATE: 'RevenueStreams/',
        CREATE: 'RevenueStreams',
        GET_LOOKUP: 'RevenueStreams/GetLookup',
        DELETE_STREAM: "RevenueStreams/",
    },
    CHARGECODES: {
        GETALL: 'ChargeCodes',
        CREATE: 'ChargeCodes',
        UPDATE: 'ChargeCodes/',
        GENERATE_CHARGE_CODE: 'ChargeCodes/GenerateChargeCode?typesCode=',
        DELETE_CHARGE_CODE: 'ChargeCodes/',
        CHARGE_CODE: 'ChargeCodes/',
        SEARCH_CHARGE_CODE: 'ChargeCodes/Search',
        CHARGE_CODE_UPDATE_STATUS: "/UpdateStatus",
        CHARGE_CODE_BY_REVENUE: "ChargeCodes/GetChargeCodeByRevenueStream"
    },
    ORGANIZATIONS: {
        GETALL: 'Organizations',
        UPDATE: 'Organizations/',
        CREATE: 'Organizations',
        DELETE: 'Organizations/',
        GET_LOOKUP: 'Organizations/GetLookup'
    },
    DAILYWORKINGS: {
        GETALL: 'DailyWorkingHours',
        GETBYID: 'DailyWorkingHours/',
        CREATE: 'DailyWorkingHours',
        UPDATE: 'DailyWorkingHours/',
        SEARCH: 'DailyWorkingHours/Search',
        DELETE: 'DailyWorkingHours/',
        DELETE_BY_MANAGER: '/DeleteByManager',
        UPDATE_STATUS: 'DailyWorkingHours/UpdateStatus/',
    },
    TIMESHEETS: {
        APPROVE: 'TimeSheets/ApproveTimesheet',
        SUBMIT: 'TimeSheets/SubmitTimesheet',
        REJECT: 'TimeSheets/RejectTimesheet',
        WITHDRAW: 'TimeSheets/WithdrawTimesheet',
        EDIT: '/EditTimesheet',
        CREATE: 'TimeSheets/CreateTimesheet',
        TIMESHEET: 'TimeSheets/'
    },
    LOOKUPS: {
        NATIONALITY: 'Lookups/API/Nationality',
        EMPLOYEETYPE: 'Lookups/API/EmployeeType',
    },
    EMPLOYEETIMESHEETS: {
        GET_SUBORDINATE_TIMESHEET: 'SubordinateTimesheets/GetSubordinateTimesheet',
        GET_SUBORDINATE_EMPLOYEE: 'SubordinateTimesheets/GetSubordinateEmployee',
        CREATE: 'SubordinateTimesheets',
        APPROVE: 'SubordinateTimesheets/ApproveSubordinateTimesheet',
        WITHDRAW: 'SubordinateTimesheets/WithdrawSubordinateTimesheet',
        UPDATE: 'SubordinateTimesheets/',
        DELETE: 'SubordinateTimesheets/'
    },
    CONFIGURATIONHOURS: {
        GETALL: 'ConfigurationHours/',
        CREATE: 'ConfigurationHours/',
        UPDATE: 'ConfigurationHours/',
        GET_CONFIGURATION_HOUR_ID: 'ConfigurationHours/',
    }
};

export default APIConstants;