import APIConstants from '../../Config/Settings/API';
import { WebServiceClient } from './WebServiceClient';

export class GlobalServices {
    public static GetLocalResources = async (locale: any) => {
        try {
            const response = await WebServiceClient.get(APIConstants.LOCALERESOURCES + "?locale=" + locale);

            return response.data;
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
}