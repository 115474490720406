import React from 'react';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuth } from '../Store/Reducers/Auth';

const ProtectedRoute = ({ component, path }: any) => {
  const state = useSelector(state => state);
  const auth = getAuth(state)

  return auth.isLoggedIn ?
    <Route component={component} path={path} /> :
    <Redirect to="/" />;
};

ProtectedRoute.propTypes = {
  component: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
};

export default ProtectedRoute;