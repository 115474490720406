import React from 'react';
import styled from 'styled-components';
import CircularSpinner from './CircularSpinner';

const SpinFallback = (props: any) => {
    return (
        <SpinnerContainer>
            <CircularSpinner />
        </SpinnerContainer>
    );
};

//#region :: Styled Components
const SpinnerContainer: any = styled.div`
display:flex;
height: 100vh;
margin-top:calc(50vh - 9vh);
flex-grow:1;
align-content:center;
justify-content:center;
`;
//#endregion

export default SpinFallback;