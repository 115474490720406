import { useIntl } from 'react-intl';
import {
    AppResources
} from '../../Services/Internationalization/Resources';

const useTranslation = () => {
    const intl = useIntl();

    const T = (id: any, value = {}) => {
        return intl.formatMessage({ id, description: id, defaultMessage: id }, value = { ...value })
    };

    return {
        T,
        Resources: {
            AppResources
        }
    };
};

export default useTranslation;