import { createSlice } from '@reduxjs/toolkit';

const exportData = createSlice({
    name: 'exportData',
    initialState: {
        listData: []
    },
    reducers: {
        setExportData: (exportData, { payload }) => {
            exportData.listData = payload.data;
        }
    }
});

export const { setExportData } = exportData.actions;

export default exportData.reducer;

//#region :: Selectors
export const getExportData = (state: any) => {
    return state.exportData.listData;
};

export const exportDataSelectors = {
    getExportData
};
//#endregion