const PagesRoute = {
    PAGES: {
        HOME: `${process.env.REACT_APP_BASE_URL}/home`,
        EMPLOYEES: `${process.env.REACT_APP_BASE_URL}/employees`,

        Approvals: `${process.env.REACT_APP_BASE_URL}/Approvals`,
        GENERAL_REPORTS: `${process.env.REACT_APP_BASE_URL}/General_Report`,
        SUMMARY_REPORT: `${process.env.REACT_APP_BASE_URL}/Summary_Report`,
        COST_REPORT: `${process.env.REACT_APP_BASE_URL}/Cost_Report`,


        JOBS_PROCEDURES: `${process.env.REACT_APP_BASE_URL}/jobsProcedures`,
        VIEW_JOBS_PROCEDURES: `${process.env.REACT_APP_BASE_URL}/jobsProcedures/viewJobProcedure/`,

        JOB_DATA: `${process.env.REACT_APP_BASE_URL}/jobData`,

        EMPLOYEE_TIMESHEET: `${process.env.REACT_APP_BASE_URL}/employee_timesheet`,
        MY_TIMESHEET: `${process.env.REACT_APP_BASE_URL}/my_timesheet`,


        ORGANIZATION_STRUCTURE: `${process.env.REACT_APP_BASE_URL}/organization_structure`,

        REVENUE_STREAMS: `${process.env.REACT_APP_BASE_URL}/revenue_streams`,


        PROJECTS: `${process.env.REACT_APP_BASE_URL}/projects`,

        CHANGE_PASSWORD: `${process.env.REACT_APP_BASE_URL}/change_password`,

        RESET_PASSWORD: `${process.env.REACT_APP_BASE_URL}/reset_password`,

        CHARGE_CODES: `${process.env.REACT_APP_BASE_URL}/charge_codes`,
        CONFIGURATION_HOUR: `${process.env.REACT_APP_BASE_URL}/configuration_hour`,


        BLANK_PAGE: `${process.env.REACT_APP_BASE_URL}/blankPage`,
    }
};

export default PagesRoute;