import AssessmentIcon from "@mui/icons-material/Assessment";
import BadgeIcon from "@mui/icons-material/Badge";
import CloseIcon from "@mui/icons-material/Close";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import QrCodeIcon from "@mui/icons-material/QrCode";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import SummarizeIcon from "@mui/icons-material/Summarize";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import TerminalIcon from "@mui/icons-material/Terminal";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton/IconButton";
import List from "@mui/material/List";
import useMediaQuery from "@mui/material/useMediaQuery";
import propTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import PagesRoute from "../../../Config/Settings/PagesRoute";
import useTranslation from "../../../Hooks/Shared/useTranslation";
import { GlobalService } from "../../../Services/global.service";
import logo from "../../../assets/images/xlogo.png";
import ListItems from "./ListItems";

const DrawerMenu = ({ handleDrawerOpen, collapseMenu }) => {
  //#region :: State
  const { T, Resources } = useTranslation();
  const screenSize = useMediaQuery((theme) =>
    theme.breakpoints.up("MobileScreen")
  );

  const currentUser = GlobalService.AppUser;
  const SystemAdmin = currentUser && currentUser?.isSystemAdmin;
  const Manager = currentUser && currentUser?.isManager;
  const iconsStyle = { width: "18px", height: "18px", color: "#fff" };

  //#endregion

  //#region :: Menu Items
  const menuItems = [
    {
      title: T(Resources.AppResources.ORGANIZATION_STRUCTURE),
      path: PagesRoute.PAGES.ORGANIZATION_STRUCTURE,
      icon: <CorporateFareIcon />,
      badgeContent: [0],
      collapseMenu: collapseMenu,
      collapseSub: undefined,
      menuSubItems: undefined,
      permission: SystemAdmin,
      click: undefined,
    },
    {
      title: T(Resources.AppResources.REVENUE_STREAMS),
      path: PagesRoute.PAGES.REVENUE_STREAMS,
      icon: <CurrencyExchangeIcon />,
      badgeContent: [0],
      collapseMenu: collapseMenu,
      collapseSub: undefined,
      menuSubItems: undefined,
      permission: SystemAdmin,
      click: undefined,
    },
    {
      title: T(Resources.AppResources.PROJECTS),
      path: PagesRoute.PAGES.PROJECTS,
      icon: <TerminalIcon />,
      badgeContent: [0],
      collapseMenu: collapseMenu,
      collapseSub: undefined,
      menuSubItems: undefined,
      permission: SystemAdmin,
      click: undefined,
    },
    {
      title: T(Resources.AppResources.CHARGE_CODES),
      path: PagesRoute.PAGES.CHARGE_CODES,
      icon: <QrCodeIcon />,
      badgeContent: [0],
      collapseMenu: collapseMenu,
      collapseSub: undefined,
      menuSubItems: undefined,
      permission: SystemAdmin,
      click: undefined,
    },
    {
      title: T(Resources.AppResources.EMPLOYEES),
      path: PagesRoute.PAGES.EMPLOYEES,
      icon: <BadgeIcon />,
      badgeContent: [0],
      collapseMenu: collapseMenu,
      collapseSub: undefined,
      menuSubItems: undefined,
      permission: SystemAdmin,
      click: undefined,
    },
    {
      title: T(Resources.AppResources.CONFIGURATION_HOUR),
      path: PagesRoute.PAGES.CONFIGURATION_HOUR,
      icon: <QueryBuilderIcon />,
      badgeContent: [0],
      collapseMenu: collapseMenu,
      collapseSub: undefined,
      menuSubItems: undefined,
      permission: SystemAdmin,
      click: undefined,
    },
    {
      title: T(Resources.AppResources.MY_TIMESHEET),
      path: PagesRoute.PAGES.MY_TIMESHEET,
      icon: <PunchClockIcon />,
      badgeContent: [0],
      collapseMenu: collapseMenu,
      collapseSub: undefined,
      menuSubItems: undefined,
      permission: true,
      click: undefined,
    },
    {
      title: T(Resources.AppResources.EMPLOYEE_TIMESHEET),
      path: PagesRoute.PAGES.EMPLOYEE_TIMESHEET,
      icon: <EventAvailableIcon />,
      badgeContent: [0],
      collapseMenu: collapseMenu,
      collapseSub: undefined,
      menuSubItems: undefined,
      permission: SystemAdmin || Manager,
      click: undefined,
    },
    {
      title: T(Resources.AppResources.GENERAL_REPORT),
      path: PagesRoute.PAGES.GENERAL_REPORTS,
      icon: <AssessmentIcon style={iconsStyle} />,
      badgeContent: [0],
      collapseMenu: collapseMenu,
      collapseSub: undefined,
      menuSubItems: undefined,
      permission: Manager || SystemAdmin,
      click: undefined,
    },
    {
      title: T(Resources.AppResources.SUMMARY_REPORT),
      path: PagesRoute.PAGES.SUMMARY_REPORT,
      icon: <SummarizeIcon style={iconsStyle} />,
      badgeContent: [0],
      collapseMenu: collapseMenu,
      collapseSub: undefined,
      menuSubItems: undefined,
      permission: Manager || SystemAdmin,
      click: undefined,
    },
    {
      title: T(Resources.AppResources.COST_REPORT),
      path: PagesRoute.PAGES.COST_REPORT,
      icon: <DataThresholdingIcon style={iconsStyle} />,
      badgeContent: [0],
      collapseMenu: collapseMenu,
      collapseSub: undefined,
      menuSubItems: undefined,
      permission: Manager || SystemAdmin,
      click: undefined,
    },
    {
      title: T(Resources.AppResources.APPROVALS),
      path: PagesRoute.PAGES.Approvals,
      icon: <TaskAltIcon style={iconsStyle} />,
      badgeContent: [0],
      collapseMenu: collapseMenu,
      collapseSub: undefined,
      menuSubItems: undefined,
      permission: Manager,
      click: undefined,
    },
  ];

  return (
    <>
      <StyledDrawer
        className="hiddenHeaderPrint"
        collapsemenu={collapseMenu}
        screensize={screenSize}
        variant="permanent"
      >
        <List>
          <li>
            {!screenSize ? (
              <StyledCloseIcon
                color="inherit"
                aria-label="change drawer"
                onClick={handleDrawerOpen}
                edge="start"
              >
                <CloseIcon />
              </StyledCloseIcon>
            ) : null}
            <StyledLogo to={PagesRoute.PAGES.HOME}>
              <div
                style={{
                  fontFamily: "Cairo",
                  fontSize: "1.5rem",
                  fontWeight: "bolder",
                }}
                className="div-header"
              >
                <img
                  src={logo}
                  height={48}
                  style={{
                    borderRight: "2px solid gray",
                    margin: "0 5px",
                    verticalAlign: "middle",
                  }}
                />
                <span
                  className="time-sheet-logo"
                  style={{
                    color: "#11e34e",
                    verticalAlign: "middle",
                  }}
                >
                  Xocia
                  <span style={{ color: "#fff" }}>
                    Time
                    <sup style={{}}>
                      {process.env.REACT_APP_TIME_SHEET_VERSION}
                    </sup>
                  </span>
                </span>
              </div>
            </StyledLogo>
          </li>
        </List>
        <List>
          {menuItems?.map((item) => {
            return Manager || SystemAdmin ? (
              item.permission === true || item.permission === undefined ? (
                <ListItems
                  key={item.title}
                  itemTitle={item.title}
                  path={item.path}
                  iconSrc={item.icon}
                  badgeContent={item.badgeContent}
                  collapseMenu={item.collapseMenu}
                  collapseSub={item.collapseSub}
                  menuSubItems={item.menuSubItems}
                  onClick={item.click}
                />
              ) : null
            ) : item.permission === true || item.permission === undefined ? (
              <ListItems
                key={item.title}
                itemTitle={item.title}
                path={item.path}
                iconSrc={item.icon}
                badgeContent={item.badgeContent}
                collapseMenu={item.collapseMenu}
                collapseSub={item.collapseSub}
                menuSubItems={item.menuSubItems}
                onClick={item.click}
              />
            ) : null;
          })}
        </List>
      </StyledDrawer>
    </>
  );
};

//#region :: Styled Components
const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(({ theme, collapsemenu, screensize }) => ({
  "&.MuiDrawer-root": {
    width: collapsemenu ? drawerWidth : theme.spacing(9),
    flexShrink: 0,
    whiteSpace: "nowrap",
    overflowX: !collapsemenu ? "hidden" : "auto",
    visibility: !collapsemenu && !screensize ? "hidden" : "visible",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: collapsemenu
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
    [!collapsemenu && theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  "& .MuiPaper-root": {
    paddingTop: "25px",
    paddingBottom: "50px",
    width: collapsemenu ? drawerWidth : theme.spacing(9),
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "103% !important",
    overflowX: !collapsemenu ? "hidden" : "auto",
    visibility: !collapsemenu && !screensize ? "hidden" : "visible",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: collapsemenu
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
    [!collapsemenu && theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    background: "#2a65a5",
    color: theme.status.white,
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px #697dbd70",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      outline: "1px solid #697DBD",
    },
  },
}));

const StyledCloseIcon = styled(IconButton)(({}) => ({
  float: "right",
  fontSize: 18,
  color: "#fff",
}));

const StyledLogo = styled(NavLink)(({ theme }) => ({
  width: "100%",
  fontSize: 22,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.status.white,
  cursor: "pointer",
  margin: "-17px 0 0 0",
  textDecoration: "none",
}));
//#endregion

//#region :: Validation PropTypes
DrawerMenu.propTypes = {
  handleDrawerOpen: propTypes.func,
  collapseMenu: propTypes.bool,
};
//#endregion

export default DrawerMenu;
