import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetEDTDateFromDateTimeNullable } from "../../../Utils/DateUtils";

const costReport = createSlice({
    name: "costReport",
    initialState: {
        ListOfReports: {},
        keys: [],
        ReportDetails: {},
        searchCriteria: {
            startDate: null,
            endDate: null,
            employeeIds: null,
            revenueStreamIds: null,
            chargeCodeIds: null
        },
        showError: false,
        error: null
    },
    reducers: {
        reportsRecived: (costReport: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {
                costReport.ListOfReports[item.id] = item;
                costReport.keys = [...costReport.keys, item.id]
            });

            costReport.ReportDetails = {};
        },

        reportUpdated: (costReport: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;

            costReport.ListOfReports[data.id] = { ...costReport.ListOfReports[data.id], isActive: !data.isActive };
            costReport.keys = [...costReport.keys, data.id];
        },

        setReport: (costReport: any, { payload }: PayloadAction<any>) => {
            costReport.reportDetails = payload.data;
        },
        setError: (costReport: any, { payload }: PayloadAction<any>) => {
            costReport.error = payload.response;
            costReport.showError = true;
        },

        setSearchCriteria: (costReport: any, { payload }: PayloadAction<any>) => {
            const { startDate, endDate, employeeIds, revenueStreamIds, chargeCodeIds } = payload.body;

            costReport.searchCriteria = {
                startDate: GetEDTDateFromDateTimeNullable(startDate),
                endDate: GetEDTDateFromDateTimeNullable(endDate),
                employeeIds: employeeIds,
                revenueStreamIds: revenueStreamIds,
                chargeCodeIds: chargeCodeIds
            };
        },
        resetSearchCriteria: (costReport: any) => {
            costReport.searchCriteria = {
                startDate: null,
                endDate: null,
                employeeIds: [],
                revenueStreamIds: [],
                chargeCodeIds: []
            };
        }
    }
});

export const {
    reportsRecived,
    setReport,
    setError,
    setSearchCriteria,
    resetSearchCriteria,
    reportUpdated
} = costReport.actions;

export default costReport.reducer;

//#region :: Selectors
export const getReportsData = (state: any) => {
    return state.costReport.ListOfReports;
};

export const getSearchCriteria = (state: any) => {
    return state.costReport.searchCriteria;
};
export const getError = (state: any) => {
    return state.costReport.error;
};
export const getchargeCodesKeys = (state: any) => {
    return state.costReport.keys;
};

export const getReportDetails = (state: any) => {
    return state.costReport.ReportDetails;
};
export const getShowError = (state: any) => {
    return state.costReport.showError;
};

export const costReportSelectors = {
    getSearchCriteria,
    getReportDetails,
    getReportsData,
    getError,
    getShowError,
    getchargeCodesKeys
};
//#endregion