import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export interface FullPageFallbackProps {
    loading?: any
};

const FullPageFallback = ({ loading }: FullPageFallbackProps) => {

    return (
        <StyledBackDrop open={loading}>
            <CircularProgress color="inherit" />
        </StyledBackDrop>
    );
};

//#region :: Styled Components
const StyledBackDrop: any = styled(Backdrop)`
    &.MuiBackdrop-root {
        z-index: 9999999;//${({ theme }: any) => theme && theme.zIndex.drawer + 1};
        color:  ${({ theme }: any) => theme && theme.status.white};
    }
`;
//#endregion

//#region :: Validation PropTypes
FullPageFallback.propTypes = {
    loading: propTypes.bool
};
//#endregion

export default FullPageFallback;