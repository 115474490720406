import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const approvalReport = createSlice({
    name: "approvalReport",
    initialState: {
        approvals: {},
        keys: [],
        approvalDetails: {},
        searchCriteria: {
            employeeId: null,
            organizationId: null,
            directReportees: null

        },
        showError: false,
        error: null
    },
    reducers: {
        setSearchCriteria: (approvalReport: any, { payload }: PayloadAction<any>) => {
            const { employeeId, organizationId, directReportees } = payload.body;

            approvalReport.searchCriteria = {
                employeeId: employeeId,
                organizationId: organizationId,
                directReportees: directReportees
            };
        }
    }
});

export const {
    setSearchCriteria
} = approvalReport.actions;

export default approvalReport.reducer;

export const getSearchCriteria = (state: any) => {
    return state.approvalReport.searchCriteria;
};

export const approvalReportSelectors = {
    getSearchCriteria
};
//#endregion