import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const employeesManagement = createSlice({
    name: "employeesManagement",
    initialState: {
        employees: {},
        keys: [],
        employeesDetails: {},
        searchCriteria: {
            searchText: '',
            gender: null,
            departmentId: null,
            status: null

        },
        showError: false,
        error: null,
    },
    reducers: {
        employeesRecived: (employeesManagement: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {
                employeesManagement.employees[item.id] = item;
                employeesManagement.keys = [...employeesManagement.keys, item.id];
            });
            employeesManagement.employeesDetails = {};
        },

        employeeUpdated: (employeesManagement: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;

            employeesManagement.employees[data.id] = { ...employeesManagement.employees[data.id], isActive: !data.isActive };
            employeesManagement.keys = [...employeesManagement.keys, data.id];
        },

        deleteEmployeesById: (employeesManagement: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            const employeesId = payload.employeesId;
            const filteredkeys = employeesManagement.keys.filter((x: any) => x != employeesId);

            employeesManagement.keys = filteredkeys;
        },
        setEmployee: (employeesManagement: any, { payload }: PayloadAction<any>) => {
            employeesManagement.employeesDetails = payload.data;
        },

        setSearchCriteria: (employeesManagement, { payload }) => {
            const { searchText, gender, departmentId, status } = payload.body;

            employeesManagement.searchCriteria = {
                searchText: searchText,
                gender: gender === 0 ? null : gender,
                departmentId: departmentId,
                status: status
            };
        },

        resetSearchCriteria: (employeesManagement: any) => {
            employeesManagement.searchCriteria = {
                searchText: '',
                gender: null,
                departmentId: null,
                status: null
            };
        },

        setError: (employeesManagement: any, { payload }: PayloadAction<any>) => {
            employeesManagement.error = payload.response;
            employeesManagement.showError = true;
        },
    },
});

export const {
    employeesRecived,
    setEmployee,
    setSearchCriteria,
    resetSearchCriteria,
    deleteEmployeesById,
    setError,
} = employeesManagement.actions;

export default employeesManagement.reducer;

//#region :: Selectors
export const getemployeesData = (state: any) => {
    return state.employeesManagement.employees;
};

export const getEmployee = (state: any) => {
    return state.employeesManagement.employeesDetails;
};

export const getemployeesKeys = (state: any) => {
    return state.employeesManagement.keys;
};

export const getSearchCriteria = (state: any) => {
    return state.employeesManagement.searchCriteria;
};

export const getShowError = (state: any) => {
    return state.employeesManagement.showError;
};

export const getError = (state: any) => {
    return state.employeesManagement.error;
};

export const employeesSelectors = {
    getemployeesData,
    getemployeesKeys,
    getSearchCriteria,
    getShowError,
    getError,
};
//#endregion
