import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const projectsManagement = createSlice({
  name: "projectsManagement",
  initialState: {
    projects: {},
    keys: [],
    projectsDetails: {},
    searchCriteria: {
      name: ''
    },
    showError: false,
    error: null,
  },
  reducers: {
    projectsRecived: (projectsManagement: any, { payload }: PayloadAction<any>) => {
      const data = payload.body;
      data.map((item: any) => {
        projectsManagement.projects[item.id] = item;
        projectsManagement.keys = [...projectsManagement.keys, item.id];
      });
      projectsManagement.projectsDetails = {};
    },

    projectUpdated: (projectsManagement: any, { payload }: PayloadAction<any>) => {
      const data = payload.body;

      projectsManagement.projects[data.id] = { ...projectsManagement.projects[data.id], isActive: !data.isActive };
      projectsManagement.keys = [...projectsManagement.keys, data.id];
    },

    deleteProjectsById: (projectsManagement: any, { payload }: PayloadAction<any>) => {
      const data = payload.body;
      const projectsId = payload.projectsId;
      const filteredkeys = projectsManagement.keys.filter((x: any) => x != projectsId);

      projectsManagement.keys = filteredkeys;
    },
    setProject: (projectsManagement: any, { payload }: PayloadAction<any>) => {
      projectsManagement.projectsDetails = payload.data;
    },

    setSearchCriteria: (projectsManagement, { payload }) => {
      const { name } = payload.body;

      projectsManagement.searchCriteria = {
        name: name
      };
    },

    setError: (projectsManagement: any, { payload }: PayloadAction<any>) => {
      projectsManagement.error = payload.response;
      projectsManagement.showError = true;
    },
  },
});

export const {
  projectsRecived,
  setProject,
  setSearchCriteria,
  deleteProjectsById,
  setError,
} = projectsManagement.actions;

export default projectsManagement.reducer;

//#region :: Selectors
export const getprojectsData = (state: any) => {
  return state.projectsManagement.projects;
};

export const getProject = (state: any) => {
  return state.projectsManagement.projectDetails;
};

export const getprojectsKeys = (state: any) => {
  return state.projectsManagement.keys;
};

export const getSearchCriteria = (state: any) => {
  return state.projectsManagement.searchCriteria;
};

export const getShowError = (state: any) => {
  return state.projectsManagement.showError;
};

export const getError = (state: any) => {
  return state.projectsManagement.error;
};

export const projectsSelectors = {
  getprojectsData,
  getprojectsKeys,
  getSearchCriteria,
  getShowError,
  getError,
};
//#endregion
