import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import ProtectedRoute from '../Routes/ProtectedRoute';
import Auth from '../Routes/Auth';
import Internal from '../Routes/Internal';
import { getAuth } from '../Store/Reducers/Auth';

const RoutConfig = () => {
    const state = useSelector(state => state);
    const auth = getAuth(state);
    const [path, setPath] = useState(window.location.pathname);

    //#region :: [Effect] Mount Window pathF
    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setPath(window.location.pathname);
        }

        // return () =>  unmounted = true;
    }, [setPath]);
    //#endregion

    return (
        <Router basename=''>
            {!auth.isLoggedIn ?
                <Route path='/' component={Auth} /> :
                <ProtectedRoute path={`${process.env.REACT_APP_BASE_URL}`} component={Internal} />}

            {auth.isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) &&
                (<Redirect to={`${process.env.REACT_APP_BASE_URL}`} />)}
        </Router>
    )
};
export default RoutConfig;