import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const timeSheet = createSlice({
    name: "timeSheet",
    initialState: {
        timeSheet: {},
        todayDate: new Date(),
        valid: true,
        keys: [],
        timeSheetDetails: {},
        searchCriteria: {},
        showError: false,
        error: null,
        weekPickerDate: new Date(),
        myTimesheetTotalHour: 0
    },
    reducers: {
        timeSheetRecived: (timeSheet: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {
                timeSheet.timeSheet[item.Id] = item;
                timeSheet.keys = [...timeSheet.keys, item.Id];
            });
        },
        deleteTimeSheetById: (timeSheet: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            const timeSheetId = payload.timeSheetId;
            const filteredkeys = timeSheet.keys.filter((x: any) => x != timeSheetId);

            timeSheet.keys = filteredkeys;
        },
        setSearchCriteria: (timeSheet: any, { payload }: PayloadAction<any>) => {
            const data = payload.response;
            timeSheet.searchCriteria = data;
        },
        setValidTime: (timeSheet: any, { payload }: PayloadAction<any>) => {
            console.log("payload ", payload.valid);
            timeSheet.valid = payload.valid;
        },
        setTodayTime: (timeSheet: any, { payload }: PayloadAction<any>) => {
            console.log("payload ", payload.date);
            timeSheet.todayDate = payload.date;
        },
        setError: (timeSheet: any, { payload }: PayloadAction<any>) => {
            timeSheet.error = payload.response;
            timeSheet.showError = true;
        },
        setWeekPickerDate: (timeSheet: any, { payload }: PayloadAction<any>) => {
            console.log("payload week picker", payload.date);
            timeSheet.weekPickerDate = payload.date;
        },
        setMyTimesheetTotalHour: (timeSheet: any, { payload }: PayloadAction<any>) => {
            timeSheet.myTimesheetTotalHour = payload.total;
        }
    }
});

export const {
    timeSheetRecived,
    setSearchCriteria,
    deleteTimeSheetById,
    setError,
    setTodayTime,
    setValidTime,
    setWeekPickerDate,
    setMyTimesheetTotalHour
} = timeSheet.actions;

export default timeSheet.reducer;

//#region :: Selectors
export const getTimeSheet = (state: any) => {
    return state.timeSheet.timeSheet;
};

export const getTimeSheetTodayDate = (state: any) => {
    return state.timeSheet.todayDate;
};

export const getTimeSheetValidation = (state: any) => {
    return state.timeSheet.valid;
};

export const getTimeSheetKeys = (state: any) => {
    return state.timeSheet.keys;
};

export const getSearchCriteria = (state: any) => {
    return state.timeSheet.searchCriteria;
};

export const getShowError = (state: any) => {
    return state.timeSheet.showError;
};

export const getError = (state: any) => {
    return state.timeSheet.error;
};

export const getWeekPickerDate = (state: any) => {
    return state.timeSheet.weekPickerDate;
};

export const getMyTimesheetTotalHour = (state: any) => {
    return state.timeSheet.myTimesheetTotalHour;
};

export const timeSheetSelectors = {
    getTimeSheet,
    getTimeSheetTodayDate,
    getTimeSheetValidation,
    getTimeSheetKeys,
    getSearchCriteria,
    getShowError,
    getError,
    getWeekPickerDate,
    getMyTimesheetTotalHour
};
//#endregion