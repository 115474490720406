let responseObject: any = {};
let responseFormValidation: any = {
    responseObject: responseObject,
    isValidForm: true
};

const requiredHandler = (value?: any, name?: any, message?: any) => {
    let dateObject = {
        valid: true,
        name: '',
        message: ''
    };

    if (value === "" || value === undefined || value == null || value.length === 0 || value === '0') {
        dateObject.valid = false;
        dateObject.name = name;
        dateObject.message = message;
    }
    else {
        dateObject.valid = true;
        dateObject.name = name;
        dateObject.message = '';
    }

    return dateObject;
};

const dontValidate = (name?: any) => {
    let dateObject = {
        valid: true,
        name: '',
        message: ''
    };

    dateObject.valid = true;
    dateObject.name = name;
    dateObject.message = '';

    return dateObject;
};

const checkInputRegex = (value?: any, name?: any, regexValue?: any, message?: any) => {
    const validRegex = regexValue.test(value);
    let dateObject = {
        valid: true,
        name: '',
        message: ''
    };

    if (value !== "") {
        if (validRegex) {
            dateObject.valid = true;
            dateObject.name = name;
            dateObject.message = '';
        }
        else {
            dateObject.valid = false;
            dateObject.name = name;
            dateObject.message = message;
        }
    }
    else {
        dateObject.valid = true;
        dateObject.name = name;
        dateObject.message = '';
    }

    responseObject[name] = dateObject;

    return responseObject[name];
};

export const compareDateFromTo = (dateFrom?: any, DateTo?: any, message?: any) => {

    let dateObject = {
        valid: true,
        error: false,
        message: ""
    };

    if (dateFrom !== "" && DateTo !== "") {
        var dateFromValue = new Date(new Date(dateFrom).toDateString());
        var dateToValue = new Date(new Date(DateTo).toDateString());
        if (dateFromValue < dateToValue) {
            return dateObject;

        }
        else {
            dateObject.message = message;
            dateObject.valid = false;
            dateObject.error = true;

            return dateObject;
        }
    }
    else {
        return dateObject;
    }
};

export const validationTypes = {
    EMAIL: 'email',
    NUMBER: 'number',
    TEXT: 'text',
    DATE: 'date',
    PHONENUMBER: 'PhoneNumber',
    ARABICNAME: 'arabicName',
    ENGLISHNAME: 'englishName',
    CODE: 'code',
    MOBILENUMBER: 'mobileNumber',
    AMOUNT: 'amount',
};

export const blockInvalidNumber = (e?: any) => (
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
);

export const Validation = (formInputs?: any) => {
    let isValidForm = true;
    let keyss: Array<any> = [];

    Object.keys(formInputs).map(function (key) {
        keyss.push(key);
    });

    keyss.map((item) => {

        let input = formInputs[item];
        let name = input.name;

        if (!input.validate) {
            let dontValidateResponse = dontValidate(name);
            responseObject[name] = dontValidateResponse;
        }
        else if (input.regexValue !== "" && input.regexValue !== undefined && input.value !== "" && input.value !== undefined)// && input.value !== "0"
        {
            let regexResponse = checkInputRegex(input.value, input.name, input.regexValue, input.errorMessages.error);
            responseObject[input.name] = regexResponse;
            if (regexResponse.valid === false) {
                isValidForm = false;
            }
        }
        else if (input.required) {
            let requiredResponse = requiredHandler(input.value, input.name, input.errorMessages.requiredMsg);
            responseObject[input.name] = requiredResponse;

            if (requiredResponse.valid === false) {
                isValidForm = false;
            }
        }
        else {
            let dontValidateResponse = dontValidate(name);
            responseObject[name] = dontValidateResponse;
        }
    });

    responseFormValidation.responseObject = responseObject;
    responseFormValidation.isValidForm = isValidForm;

    return responseFormValidation;
};


export const isMobile = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    }

    return false;
}

export const focusNextElement = () => {
    //add all elements we want to include in our selection
    var focussableElements = 'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
    if (document.activeElement && (<any>document.activeElement).form) {
        var focussable = Array.prototype.filter.call((<any>document.activeElement).form.querySelectorAll(focussableElements),
            function (element) {
                //check for visibility while always include the current activeElement 
                return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
            });
        var index = focussable.indexOf(document.activeElement);
        if (index > -1) {
            var nextElement = focussable[index + 1] || focussable[0];
            nextElement.focus();
        }
    }
}