import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const CircularSpinner = () => {

    return <CircularProgress />;
};

export const CenterCircularSpinner: any = styled(CircularProgress)`
    position: fixed;
    left: calc(50% - 40px);
    top: calc(50vh - 40px);
`;

export default CircularSpinner;