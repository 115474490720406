import propTypes from 'prop-types';
import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

interface HeaderWraperProps {
    children?: any;
    open?: any;
};

const HeaderWraper = ({ children, open }: HeaderWraperProps) => {
    return (
        <StyledAppBar open={open} position="fixed" color='inherit'>
            <StyledToolbar>
                {children}
            </StyledToolbar>
        </StyledAppBar>
    );
};

//#region :: Styled Components
const drawerWidth: number = 240;

const StyledAppBar: any = styled(AppBar)(({ theme, open }: any) =>
    !open ? ({
        '&.MuiAppBar-root': {
            height: '64px !important',
            padingInline: '0px !important',
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            })
        }
    })
        : ({
            '&.MuiAppBar-root': {
                height: '60px !important',
                paddingRight: '0px !important',
                zIndex: theme.zIndex.drawer + 1,
                [theme.direction === 'ltr' ? 'marginLeft' : 'marginRight']: drawerWidth,
                [theme.breakpoints.down('sm')]: {
                    width: `calc(100% - 0px)`,
                },
                [theme.breakpoints.up('MobileScreen')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                },
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                })
            }
        })
);

const StyledToolbar: any = styled(Toolbar)(({ theme }: any) => ({
    backgroundColor: theme.status.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingInlineStart: '24px !important',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));
//#endregion

//#region :: Validation PropTypes
HeaderWraper.propTypes = {
    children: propTypes.any,
    open: propTypes.bool
};
//#endregion

export default HeaderWraper;