
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { PaginationEnums } from '../../Config/Settings/Enums';

export const PaginationModules = {
    CHARGE_CODE: 'chargeCode',
    GENERAL_REPORT: 'generalReport',
    PROJECT: 'project',
    EMPLOYEE: 'employee',
    COST_REPORT: 'costReport'
};

const DEFAULT_PAGE_SIZE = PaginationEnums.PAGE_SIZE;
const modulesPageSaize: any = {
    // [PaginationModules.ACTIVEDIRECTORYUSERS]: PaginationEnums.SMALL_PAGE_SIZE
};

const DEFAULT_CONTEXT_NAME = 'defaultContext';

const paginationSlice = createSlice({
    name: 'pagination',
    initialState: {
        pageSize: DEFAULT_PAGE_SIZE,
        [PaginationModules.CHARGE_CODE]: {
        },
        [PaginationModules.GENERAL_REPORT]: {
        },
        [PaginationModules.PROJECT]: {
        },
        [PaginationModules.EMPLOYEE]: {
        },
        [PaginationModules.COST_REPORT]: {
        }
    },
    reducers: {
        pagesReseted: (pagination: any, { payload }: PayloadAction<any>) => {
            const { module, contextId = DEFAULT_CONTEXT_NAME } = payload;
            const _module: any = pagination[module];
            _module[contextId] = null;
        },
        pageInitialized: (pagination, { payload }) => {

            const { module, contextId = DEFAULT_CONTEXT_NAME, page } = payload;

            const _module: any = pagination[module];

            if (!_module[contextId]) {
                _module[contextId] = {
                    pages: {
                        [page]: {
                            keys: [],
                            isFetching: true
                        }
                    },
                    totalCount: 0,
                    currentPage: 0,
                    pageSize: modulesPageSaize[module] || DEFAULT_PAGE_SIZE
                };
            } else {
                _module[contextId].pages[page] = {
                    keys: [],
                    isFetching: true
                };
            }
        },
        pageSaved: (pagination, { payload }) => {
            const { module, contextId = DEFAULT_CONTEXT_NAME, page, totalCount, data } = payload;

            const _module: any = pagination[module];
            const content = _module[contextId];
            if (!content || !content.pages) return;

            content.pages[page].keys = data;
            content.pages[page].isFetching = false;
            content.totalCount = totalCount;
            content.currentPage = page;
        },
        pageChanged: (pagination, { payload }) => {
            const { module, contextId = DEFAULT_CONTEXT_NAME, page } = payload;

            const _module: any = pagination[module];
            const content = _module[contextId];
            content.currentPage = page;
        }
    }
});

export const {
    pagesReseted,
    pageInitialized,
    pageSaved,
    pageChanged
} = paginationSlice.actions;

export default paginationSlice.reducer;


//#region :: Custom Actions

export const pageRequested: any = createAction('pagination/pageRequested');
export const pageRetrieved: any = createAction('pagination/pageRetrieved');
export const pageError: any = createAction('pagination/pageError');

//#endregion


//#region :: Selectors

export const getPagination = (state: any) => {
    return state.pagination;
};

export const getPageKeys = (state: any, module: any, page = 0, context = DEFAULT_CONTEXT_NAME) => {
    var _module = state.pagination[module];
    var _context = _module[context];

    if (!_context) return { keys: [] };

    return _context.pages[page];
};

export const getPaginationModule = (state: any, module: any) => {
    return state.pagination[module];
};

export const getCurrentPage = (state: any, module: any, contextId = DEFAULT_CONTEXT_NAME) => {
    const content = state.pagination[module][contextId];
    return content && content.currentPage || 0;
};

export const getPageSize = (state: any, module: any, contextId = DEFAULT_CONTEXT_NAME) => {
    const content = state.pagination[module][contextId];
    if (!content) return modulesPageSaize[module] || state.pagination.pageSize;
    return content.pageSize || state.pagination.pageSize;
};

export const getTotalPages = (state: any, module: any, contextId = DEFAULT_CONTEXT_NAME) => {
    const content = state.pagination[module][contextId];
    if (!content) return 0;
    return Math.ceil(content.totalCount / content.pageSize);
};

export const getCountFetchedPages = (state: any, module: any, contextId = DEFAULT_CONTEXT_NAME) => {
    const _module = state.pagination[module];
    if (!_module) return false;

    const content = _module[contextId];

    return content && content.pages && Object.keys(content.pages).length || 0;
};

export const getLastPageIndex = (state: any, module: any, contextId = DEFAULT_CONTEXT_NAME) => {
    const _module = state.pagination[module];
    if (!_module) return false;

    const content = _module[contextId];


    return content && content.pages && parseInt(Object.keys(content.pages)[Object.keys(content.pages).length - 1]) || 0;
};

export const hasNextPage = (state: any, module: any, contextId = DEFAULT_CONTEXT_NAME) => {
    const _module = state.pagination[module];
    if (!_module) return false;

    const content = state.pagination[module][contextId];
    if (!content) return false;

    return (content.currentPage || 0) + 1 < getTotalPages(state, module, contextId);
};

export const hasMore = (state: any, module: any, contextId = DEFAULT_CONTEXT_NAME) => {
    return getLastPageIndex(state, module, contextId) + 1 < getTotalPages(state, module, contextId) || false;
};

export const isPageFetching = (state: any, module: any, contextId = DEFAULT_CONTEXT_NAME, page?: any) => {
    const _module = state.pagination[module];
    if (!_module) return false;

    const content = _module[contextId];

    //If [page] not specified, check is any page currently fetching:
    if (page === undefined && content && content.pages) {
        return Object.keys(content.pages).some(x => content.pages[x].isFetching);
    }

    //Is Specific page currently fetching:
    return content && content.pages[page] && content.pages[page].isFetching || false;

};

export const isPageExists = (state: any, module: any, contextId = DEFAULT_CONTEXT_NAME, page = 0) => {

    const _module = state.pagination[module];
    if (!_module) return false;

    const content = _module[contextId];

    return content && content.pages[page] && true;
};

export const PaginationSelectors = {
    getPagination,
    getPageKeys,
    getCurrentPage,
    getPageSize,
    getTotalPages,
    getCountFetchedPages,
    hasNextPage,
    hasMore,
    isPageFetching,
    getPaginationModule,
    getLastPageIndex,
    isPageExists
};

//#endregion