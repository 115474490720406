import React from 'react';
import { connect } from 'react-redux';

const AuthLayout = (Content: any) => {

    const Layout = (props: any) => {
        return (
            <>
                <div>
                    <Content {...props} />
                </div>
            </>
        )
    };

    //#region :: Map State To Component
    const mapStateToProps = (state: any) => {
        return {
        };
    };

    const mapStateToDispatch = (dispatch: any) => {
        return {
        };
    };
    //#endregion

    //#region :: Validation PropTypes
    Layout.propTypes = {
    };
    //#endregion

    return connect(mapStateToProps, mapStateToDispatch)(Layout);
};

export default AuthLayout;