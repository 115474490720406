import { IdentityTypes } from "../Enums";

export const regexType = {
    EMAIL: /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
    MOBILENUMBER: /^(((05)[0-9]{8})|((00)[0-9]{8,13}))$/,
    USERMOBILENUMBER: /^((05)[0-9]{8})$/,
    ARABICNAME: /^[\u0621-\u064A-\s ]{0,50}$/,
    ENGLISHNAME: /^[A-Za-z][A-Za-z-\s ]{0,50}$/,
    CODE: /^[A-Za-z]{1,8}$/,
    NUMBER: /^[0-9]+$/,
    SERVICEFEES: /^([1-9][0-9]{0,5}(\.[0-9]{1,2})?)$|^0(\.[0-9]{1,2})$/,
    BILLNUMBER: /^\d{5,16}$/,
    PERIOD: /^[1-9][0-9]*$/,
    GREATERTHANZERO: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
    INTERGERPOSITIVENUMBER: /^[1-9]\d*$/,
    CHARS1000: /^[\s\S]{0,1000}$/,
};

export const regexMsgs = {
    INVALID_EMAIL: "INVALID_EMAIL",
    CUSTOMER_MOBILE_NUMBER_ERROR: "CUSTOMER_MOBILE_NUMBER_ERROR",
    USER_MOBILE_NUMBER_ERROR: "USER_MOBILE_NUMBER_ERROR",
    INVALID_ARABIC_NAME: "INVALID_ARABIC_NAME",
    INVALID_ENGLISH_NAME: "INVALID_ENGLISH_NAME",
    INVALID_CATEGORY_CODE: "INVALID_CATEGORY_CODE",
    NUMBER_ERROR_MSG: "NUMBER_ERROR_MSG",
    REQUIRED_ERROR_MSG: "REQUIRED_ERROR_MSG",
    BILL_INVALID_AMOUNT: "BILL_INVALID_AMOUNT",
    POSITIVE_NUMBER: "POSITIVE_NUMBER",
    SERVICE_AMOUNT_ERROR: "SERVICE_AMOUNT_ERROR",
    PERIOD: "PERIOD",
    CHARS_1000: 'chars_1000'
};

export const IdentityTypesValidation = {
    [IdentityTypes.NATIONAL_IDENTITY]: { id: [IdentityTypes.NATIONAL_IDENTITY], regex: /^(1)[0-9]{9}$/ },
    [IdentityTypes.IQAMA_IDENTITY]: { id: [IdentityTypes.IQAMA_IDENTITY], regex: /^((2)[0-9]{9})$/ },
    [IdentityTypes.COMMERCIAL_REGISTRATION_NUMBER]: { id: [IdentityTypes.COMMERCIAL_REGISTRATION_NUMBER], regex: /^[0-9]{10}$/ },
    [IdentityTypes.UNIFIED_ORGANIZATION_NUMBER]: { id: [IdentityTypes.UNIFIED_ORGANIZATION_NUMBER], regex: /^((7)[0-9]{9})$/ },
    [IdentityTypes.CITIZENS_OF_GULF_COUNTRIES]: { id: [IdentityTypes.CITIZENS_OF_GULF_COUNTRIES], regex: /^[A-Za-z0-9]{9}$/ },
    [IdentityTypes.PASSPORT]: { id: [IdentityTypes.PASSPORT], regex: /^[A-Za-z0-9]{9}$/ },
    [IdentityTypes.BORDER_NUMBER]: { id: [IdentityTypes.BORDER_NUMBER], regex: /^((3)[0-9]{9}|(4)[0-9]{9})$/ },
    [IdentityTypes.FAMILY_CARD_NUMBER]: { id: [IdentityTypes.FAMILY_CARD_NUMBER], regex: /^(1)[0-9]{9}$/ },
};