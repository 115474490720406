
import { combineReducers } from '@reduxjs/toolkit';
import ui from './Reducers/UI';
import auth from './Reducers/Auth';
import exportData from './Reducers/ExportData';
import pagination from './Reducers/Pagination';
import chargeCodesManagement from './Reducers/ChargeCodes';
import projectManagement from './Reducers/Projects';
import employeesManagement from './Reducers/Employees';
import timeSheet from './Reducers/TimeSheet';
import reports from './Reducers/Reports';
import costReport from './Reducers/CostReport';
import employeeTimesheetManagement from './Reducers/EmployeeTimesheet';
import summaryReport from './Reducers/SummaryReport';
import approvalReport from './Reducers/ApprovalReport';

export default combineReducers({
    ui,
    auth,
    chargeCodesManagement,
    exportData,
    timeSheet,
    reports,
    pagination,
    projectManagement,
    employeesManagement,
    employeeTimesheetManagement,
    summaryReport,
    costReport,
    approvalReport
});