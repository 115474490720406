import axios from "axios";
import { createBrowserHistory } from "history";
import APIConstants from "../../Config/Settings/API";
import { StatusCode } from "../../Config/Settings/Constants/StatusCode";
import CookiesConstants from "../../Config/Settings/Cookies";
import LocalStorageConstants from "../../Config/Settings/LocalStorage";
import { setCookie } from "../../Utils/Cookies";
import { getItem } from "../../Utils/LocalStorage";

const history = createBrowserHistory();

const API_ENDPOINT = APIConstants.BASE_URL;
const ACCESS_TOKEN = LocalStorageConstants.ACCESS_TOKEN;

const authHeader = () => ({
  Authorization: `Bearer ${getItem(ACCESS_TOKEN)}`,
});

axios.defaults.baseURL = API_ENDPOINT;
axios.defaults.headers.common["Authorization"] = `Bearer ${getItem(ACCESS_TOKEN)}`;
axios.defaults.headers.post["Content-Type"] = 'application/json';


const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    ...authHeader(),
    "Content-Type": "application/json",
  }
});


class WebServiceClient {
  static get(path = "") {
    return client({
      method: "GET",
      url: path,
    });
  }
  static delete(path = "") {
    return client({
      method: "DELETE",
      url: path,
    });
  }
  static post(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "POST",
      url: path,
      data,
    });
  }

  static patch(path = "", data = {}) {
    return client({
      method: "PATCH",
      url: path,
      data: JSON.stringify(data),
    });
  }

  static put(path = "", data = {}) {
    return client({
      method: "PUT",
      url: path,
      data: JSON.stringify(data),
    });
  }

  static loginPost(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "GET",
      url: path,
      data,
    });
  }

  static getError(error: any) {
    let errorResponse = error;
    if (error.response)
      errorResponse = error.response;
    else
      errorResponse = error;

    throw errorResponse;
  }

  static handleError(error: any) {
    var errorResponse = error;
    if (error.response !== undefined) {
      if (error.response.data) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach(key => {
            let errorArray = error.response.data.errors[key];
            errorArray.forEach((errMessage: string) => {
              errorResponse = errMessage;
            });
          })
        } else if (error.response.data.status)
          errorResponse = error.response.data.status;
      }
    } else {
      errorResponse = error;
    }
    return errorResponse;
  }
}

const GoTo = (page: any) => {
  window.location.pathname = `${process.env.REACT_APP_BASE_URL}/${page}`;
  // history.push(`${process.env.REACT_APP_BASE_URL}/${page}`);
  // history.go(`${process.env.REACT_APP_BASE_URL}/${page}`);
};

const configRequestInterceptor = (config: any) => {
  const requestConfig = config;

  //requestConfig.withCredentials = true;
  // const { headers } = config;
  // requestConfig.headers = { ...headers, Authorization: `Bearer ${getItem(ACCESS_TOKEN)}` };

  return requestConfig;
};

const responseErrorInterceptor = async (error: any) => {
  console.log("error", error);
  const isUserName = getItem(LocalStorageConstants.CUURENT_USERNAME);

  //#region :: Token Expired || Refresh Token Expired
  // if (error?.response?.status === StatusCode.UNAUTHORIZED) {
  //   try {
  //     const token = await RefreshToken();

  //     client.defaults.headers.common['Authorization'] = 'Bearer ' + token.data.access_token;
  //     originalRequest._retry = true;

  //     return client(originalRequest);

  //   }
  //   catch (error) {
  //     setCookie(CookiesConstants.AUTH.IS_LOGGED_IN, false);
  //     window.location.href = '/';

  //     return Promise.reject(error);
  //   }
  // }
  //#endregion

  if (error?.response?.status === StatusCode.UNAUTHORIZED) {
    console.log("UNAUTHORIZED-ERROR");
    // if (isUserName) {
    // GoTo(401);
    // GoTo('signIn');
    // dispatch(logoutSucceeded(false));
    localStorage.removeItem('userName');
    setCookie(CookiesConstants.AUTH.IS_LOGGED_IN, false);
    setCookie(CookiesConstants.AUTH.ROLE, "");
    window.location.pathname = `/`;
    // }
  }

  if (error?.response?.status === StatusCode.FORBIDDEN) {
    console.log("FORBIDDEN-ERROR");

    // if (isUserName) {
    GoTo(403);
    // }
  }

  if (error?.message?.toLowerCase() === "network error") {
    console.log("NETWORK-ERROR");

    error.response = {
      status: 503,
      message: "NETWORK-ERROR",
    };
    error.statusCode = 503;

    // if (isUserName) {
    console.log("isUserName");
    GoTo(503);
    // }
  }

  return Promise.reject({
    message: error.message,
    response: error.response,
    statusCode: error?.response?.status,
  });
};

client.interceptors.request.use(configRequestInterceptor);

client.interceptors.response.use(
  (response) => response,
  responseErrorInterceptor
);

axios.interceptors.request.use(configRequestInterceptor);

axios.interceptors.response.use(
  (response) => response,
  responseErrorInterceptor
);

export { WebServiceClient, axios };

