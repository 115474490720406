const AppResources: { [key: string]: any } = {
    TIMESHEET: 'app.TimeSheet',
    GREETING: 'app.greeting',
    TRANSACTIONID: 'app.transactionId',
    NATIONALID: 'app.nationalId',
    PATIENT_NAME: 'app.patient_Name',
    DATE_FROM: 'app.date_From',
    DATE_TO: 'app.date_To',
    TRANSACTION_TYPE: 'app.transaction_Type',
    INSURANCE_COMPANY: 'app.insurance_Company',
    PURPOSE: 'app.purpose',
    STATUS: 'app.status',
    BRANCH: 'app.branch',
    TPA: 'app.TPA',
    ACTIONS: 'app.actions',
    REPORTS: 'app.reports',
    REQUESTS: 'app.requests',
    REQUESTREPORT: 'app.request_Report',
    MOBILE_NUMBER: 'app.mobile_Number',
    SYSTEM_SETUP: 'app.system_Setup',
    SEARCH: 'app.search',
    RESET: 'app.reset',
    DASHBOARD: 'app.dashBoard',
    BENEFICIARY: 'app.beneficiary',
    CREATEBENEFICIARY: 'app.createBeneficiary',
    EDITBENEFICIARY: 'app.editBeneficiary',
    FILTER: 'app.filter',
    BLANKPAGE: 'app.blankPage',
    CLAIMS: 'app.claims',
    ELIGIBILITY: 'app.eligibilityy',
    HOME: 'app.home',
    PAYMENT: 'app.payment',
    PREAUTHORIZATION: 'app.preAuthorization',
    SETTINGS: 'app.settings',
    PROFILE: 'app.profile',
    THEME: 'app.theme',
    MY_ACCOUNT: 'app.my_account',
    USER_IMAGE: 'app.user_Image',
    DOWNLOAD: 'app.download',
    UPLOAD: 'app.upload',
    CANCEL: 'app.cancel',
    DATE_VALIDATION: 'app.date_Validation',
    SELECT: 'app.select',
    NODATA: 'app.nodata',
    DATE: 'app.date',
    NAME: 'app.name',
    DOCUMENTID: 'app.document_Id',
    FILEID: 'app.file_id',
    CONTACTNO: 'app.contact_no',
    DATEOFBIRTH: 'app.date_of_birth',
    INSURANCEPLAN: 'app.insurance_plan',
    ELIGIBILITYSTATUS: 'app.eligibility_status',
    LOCAION: 'app.location',
    IS_MANGER: 'app.is_manger',
    IS_SYSTEM_ADMIN: 'app.is_system_admin',
    MANAGER: 'app.manager',
    POSITION: 'app.position',
    TOTAL_HOURS: 'app.total_hours',
    TOTAL_HOURS_8_Ratio: 'app.total_hour_8_Ratio',
    HOURS_Ratio: 'app.hours_ratio',
    TOTAL: 'app.total',
    // PERIOD:'app.period',
    TOTAL_RESULTS: 'app.total_results',
    MEMBER_CARD_ID: 'app.member_Card_ID',
    ELIGIBILITY_ID: 'app.eligibility_ID',
    INSURANCE_PLAN: 'app.insurance_Plan',
    REQUEST_NEW_ELIGIBILITY: 'app.request_New_Eligibility',
    BENEFICIARY_ID: 'app.beneficiary_ID',
    ARABIC_LANGUAGE: 'app.arabic_language',
    ENGLISH_LANGUAGE: 'app.english_language',
    CHECK_ELIGIBILITY: 'app.check_eligibility',
    FROM: 'app.from',
    TO: 'app.to',
    OK: 'app.ok',
    CLEAR: 'app.clear',
    INSURER: 'app.insurer',
    SERVICE_DATE: 'app.service_date',
    ADD_NEW_BENEFICIARY: 'app.add_new_beneficiary',
    AGE: 'app.age',

    // Start View Beneficiary
    PERSONAL_INFORMATION: 'app.personal_information',
    ENEFICIARY_FILE_ID: 'app.beneficiary_file_id',
    BENEFICIARY_NAME: 'app.beneficiary_name',
    GENERATE: 'app.generate',
    DATE_OF_BIRTH: 'app.date_of_birth',
    BLOOD_GROUP: 'app.blood_group',
    NATIONALITY: 'app.nationality',
    RESIDENT_TYPE: 'app.resident_type',
    DOCUMENT_TYPE: 'app.document_type',
    DOCUMENT_ID: 'app.document_id',
    PATIENT_PHONE_NUMBER: 'app.patient_phone_number',
    EMERGENCY_PHONE_NUMBER: 'app.emergency_phone_number',
    EMERGENCY_CONTACT_RELATION: 'app.emergency_contact_relation',
    EMAIL: 'app.email',
    MARTIAL_STATUS: 'app.martial_status',
    PREFERRED_LANGUAGE: 'app.preferred_language',
    ADDRESS: 'app.address',
    MEMBERSHIP_NO: 'app.membership_no',
    PPOLICY_NO: 'app.Ppolicy_no',
    GROUP_NAME: 'app.group_name',
    APPROVAL_LIMIT: 'app.approval_limit',
    NETWORK_NO: 'app.network_no',
    TERM: 'app.term',
    CO_PAY: 'app.co_pay',
    NOTES: 'app.Notes',
    APPROVALS: 'app.approvals',
    VIEWBENEFICIARY: 'app.beneficiary_details',
    WEEK_ONE: 'app.week_one',
    WEEK_TWO: 'app.week_two',
    WEEK_THREE: 'app.week_three',
    WEEK_FOUR: 'app.week_four',
    WEEK_FIVE: 'app.week_five',

    // End View Beneficiary


    // Start View Eligibility 
    ELIGIBILITY_DETAILS: 'app.eligibility_details',
    CONTACT_NUMBER: 'app.contact_number',
    COVARAGE: 'app.coverage',
    CLASS: 'app.class',
    NETWORK: 'app.network',
    DEPENDENT_RELATIONSHIP: 'app.dependent_relationship',
    ROOM_TYPE: 'app.room_type',
    PERIOD: 'app.period',
    MAX_ALLOWED: 'app. max_allowed',
    DEPARTMENT_NAME: 'app.department_name',
    DEPARTMENT_ROLE: 'app.department_role',
    TRANSACTION_DATE: 'app.transaction_date',
    RESPONSE_TIME: 'app.response_time',
    DOWNLOAD_TIME: 'app.download_time',
    ELIGIBILITY_PURPOSE: 'app.eligibility_purpose',
    INCLUSIONS: 'app.inclusions',
    UNIT: 'app.unit',
    LIMIT: 'app.limit',
    MAX_OUT_OF_POCKET: 'app.max_out_of_pocket',
    EXCEPTIONS: 'app.Exceptions',
    EXCLUSIONS: 'app. Exclusions',

    // End View Eligibility 


    HIJRI: 'app.hijri',
    SAVE: 'app.save',
    VIEWELIGIBILITY: 'app.viewEligibility',

    //Items Page
    ITEMS: 'app.items',
    ITEM: 'app.item',
    CHAPTER: 'app.chapter',
    BLOCK: 'app.block',
    ALIAS_CODE: 'app.alias_code',
    ALIAS: 'app.alias',
    CATEGORY: 'app.category',
    CREATED_DATE: 'app.created_date',
    SHORT_DESCRIPTION: 'app.short_description',
    ITEM_CODE: 'app.item_code',
    ADD_NEW_ITEM: 'app.add_new_item',
    ADDITEM: 'app.add_item',
    VIEWITEM: 'app.view_item',
    EXPORT: 'app.export',

    CODE: 'app.code',
    LONG_DESCRIPTION: 'app.long_description',
    OWNER: 'app.owner',
    ADD_CATEGORY: 'app.add_category',
    ADD_CHAPTER: 'app.add_chapter',
    ADD_BLOCK: 'app.add_block',
    ID: 'app.id',
    BACK: 'app.back',
    EDIT: 'app.edit',
    YES: 'app.yes',
    NO: 'app.no',
    DIAGNOSIS: 'app.diagnosis',
    ADD_NEW_DIAGNOSIS: 'app.add_new_diagnosis',
    PARENT: 'app.parent',
    ADDDIAGNOSIS: 'app.add_diagnosis',
    VERSION: 'app.version',
    YEAR: 'app.year',
    SECONDARY_ASSOCIATIONS: 'app.secondary_associations',
    ASSOCIATION: 'app.association',
    EDITITEM: 'app.edit_item',
    GENDER: 'app.gender',
    JOINING_DATE: 'app.join_date',
    HIRE_DATE: 'app.hire_date',
    TERMINATE_DATE: 'app.terminate_date',
    EMPLYEE_NAME: 'app.employee_name',
    // Start Practitioners
    EMPLOYEE_NUMBER: 'app.employee_number',
    PRACTITIONERS: 'app.practitioners',
    DESCRIPTION: 'app.description',
    ADD_NEW_PARTITIONER: 'app.add_new_partitioner',
    LICENSE: 'app.license',
    ENGLISH_NAME: 'app.english_name',
    SPECIALTY: 'app.Specialty',
    ROLE: 'app.role',
    ACTION_FROM: 'app.active_from',
    ACTION_TO: 'app.active_to',
    CREATEPRACTITIONERS: 'app.create_practitioners',
    CREATE_EMP: 'app.create_emp',
    EDIT_EMP: 'app.edit_emp',
    EDITPRACTITIONERS: 'app.edit_practitioners',
    PARTITIONER_CODE: 'app.partitioner_code',
    PRACTITIONER_SPECIALTY: 'app.practitioner_specialty',
    PRACTITIONER_ROLE: 'app.practitioner_role',
    VIEWPRACTITIONERS: 'app.view_practitioners ',
    ADD: 'app.add',
    TERMINOLOGY: 'app.terminology',
    ADDTERMINOLOGY: 'app.add_terminology',
    EDITTERMINOLOGY: 'app.edit_terminology',
    VIEWTERMINOLOGY: 'app.view_terminology',
    ADD_NEW_TERMINOLOGY: 'app.add_new_terminology',
    CODE_SYSTEM: 'app.code_system',
    VALUE_SET: 'app.value_set',


    // Start TypesManagement 
    SERVICES_MANAGEMENT: 'app.servicesManagement',
    TYPESMANAGEMENT: 'app.typesManagement',
    CODE_SYSTEM_URL: 'app.codeSystemUrl',
    ADD_NEW_TYPE: 'app.addNewType',
    CREATETYPESMANAGEMENT: 'app.create_Types_Management',
    EDITTYPESMANAGEMENT: 'app.edit_Types_Management',
    VIEWTYPESMANAGEMENT: 'app.types_Management_details',


    PAYERS: 'app.payers',
    PAYER: 'app.payer',
    ADDPAYER: 'app.add_payer',
    EDITPAYER: 'app.edit_payer',
    VIEWPAYER: 'app.view_payer',
    ADD_NEW_PAYER: 'app.add_new_payer',
    NAME_AR: 'app.name_ar',
    LastName: 'LastName',
    NAME_EN: 'app.name_en',
    UNIFIED_ID: 'app.unified_id',
    VAT_NUMBER: 'app.vat_number',
    COUNTRY: 'app.country',
    CITY: 'app.city',
    CONTACT_NAME: 'app.contact_name',
    PHONE_NUMBER: 'app.phone_number',
    BUILDING_NUMBER: 'app.building_number',

    STREET_NAME: 'app.street_name',
    DISTRICT: 'app.district',
    POSTAL_CODE: 'app.postal_code',
    ADDITIONAL_NUMBER: 'app.additional_number',
    CREATE: 'app.create',
    CREATE_AND_NEW: 'app.create_and_new',
    EXT: 'app.ext',


    // Start PROVIDER_GROUPS 
    PROVIDER: 'app.provider',
    PROVIDERGROUPS: 'app.provider_groups',
    ADDNEWGROUP: 'app.add_new_group',
    CREATEPROVIDERGROUPS: 'app.create_provider_groups',
    EDITPROVIDERGROUPS: 'app.edit_provider_groups',
    VIEWPROVIDERGROUPS: 'app.provider_groups_details',



    // Start Facilities 
    FACILITIES: 'app.facilities',
    HOSPITALCODE: 'app.hospitalCode',


    LICENSE_NO: 'app.license_no',
    LICENSE_PERIOD: 'app.license_period',
    LICENSE_START: 'app.license_start',
    LICENSE_END: 'app.license_end',
    CHHI_ID: 'app.chhi_id',
    ADDFACILITIES: 'app.add_facilities',
    EDITFACILITIES: 'app.edit_facilities',
    VIEWFACILITIES: 'app.view_facilities',

    SERVICECATEGORIES: 'app.service_Categories',
    VIEWSERVICECATEGORIES: 'app.view_service_Categories',
    EDITSERVICECATEGORIES: 'app.edit_service_Categories',
    ADDSERVICECATEGORIES: 'app.add_service_Categories',
    ADD_NEW_SERVICE_CATEGORIE: 'app.add_new_service_Categorie',





    SERVICESUBGROUPS: 'app.service_sub_groups',
    VIEWSERVICESUBGROUPS: 'app.view_service_sub_groups',
    EDITSERVICESUBGROUPS: 'app.edit_service_sub_groups',
    ADDSERVICESUBGROUPS: 'app.add_service_sub_groups',
    ADD_NEW_SERVICE_SUB_GROUPS: 'app.add_new_service_sub_groups',
    SERVICE_GROUP: 'app.service_group',


    SERVICECATALOG: 'app.service_catalog',
    ADDSERVICECATALOG: 'app.add_service_catalog',
    EDITSERVICECATALOG: 'app.edit_service_catalog',
    VIEWSERVICECATALOG: 'app.view_service_catalog',
    CATALOG_NAME: 'app.catalog_name',
    ADD_NEW_SERVICE_CATALOG: 'app.add_new_service_catalog',

    SERVICEITEMS: 'app.service_items',
    ADDSERVICEITEMS: 'app.add_service_items',
    EDITSERVICEITEMS: 'app.edit_service_items',
    VIEWSERVICEITEMS: 'app.view_service_items',
    SERVICEMANAGEMENTS: 'app.service_items',



    SERVICE: 'app.service',
    SERVICE_SUB_GROUP: 'app.service_sub_group',
    SERVICE_CATEGORY: 'app.service_category',


    PRICELISTS: 'app.price_lists',
    ADDPRICELISTS: 'app.add_price_lists',
    EDITPRICELISTS: 'app.edit_price_lists',
    VIEWPRICELISTS: 'app.view_price_lists',

    MALE: 'app.male',
    FEMALE: 'app.female',
    ADD_NEW_PRICE_LISTS: 'app.add_new_price_lists',
    START_DATE: 'app.start_date',
    END_DATE: 'app.end_date',

    SELECTED_FILES: 'app.selected_files',
    DRAG_AND_DROP_A_FILE_HERE_OR_CLICK: 'app.drag_and_drop_file',
    UNITPRICE: 'app.unit_price',
    VAT_CATEGORY: 'app.vat_category',
    NON_STANDARD_CODE: 'app.non_standard_code',
    PACKAGE: 'app.package',
    // Start ServiceGroups 

    SERVICEGROUPS: 'app.service_Groups',
    ADD__NEW_SERVICE_GROUPS: 'app.add_new_service_groups',
    ADDSERVICEGROUPS: 'app.add_servicegroups',
    EDITSERVICEGROUPS: 'app.edit_servicegroups',
    VIEWSERVICEGROUPS: 'app.view_servicegroups',
    LOGIN: 'app.login',
    USERNAME: 'app.username',
    PASSWORD: 'app.password',

    // Start PriceLists 
    ITEM_TYPE: 'app.item_type',
    ITEM_CATEGORY: 'app.item_category',
    ITEM_CHAPTER: 'app.item_chapter',
    ITEM_BLOCK: 'app.item_block',

    PRE_AUTHORIZATION_ID: 'app.pre_authorization_id',
    ADD_NEW_PREAUTHORIZATION: 'app.add_new_pre_authorization',
    ADDPREAUTHORIZATION: 'app.add_pre_authorization',
    EDITPREAUTHORIZATION: 'app.edit_pre_authorization',
    VIEWPREAUTHORIZATION: 'app.view_pre_authorization',

    PRE_AUTHORIZATION_INFO: 'app.pre_authorization_info',
    DATE_ORDERED: 'app.date_ordered',
    TYPE: 'app.type',
    SUBTYPE: 'app.subtype',
    SEQUENCE: 'app.sequence',
    CODE_DESCRIPTION: 'app.code_description',
    ON_ADMISSION: 'app.on_admissino',

    VIEWDIAGNOSIS: 'app.view_diagnosis',
    EDITDIAGNOSIS: 'app.edit_diagnosis',

    DOWNLOAD_SAMPLE: 'app.download_sample',

    VIEWPAYMENT: 'app.view_Payment',

    POLICYLISTS: 'app.policies_lists',
    PLANS: 'app.plans',
    INSURANCEPOLICIES: 'app.insurancePolicies',
    POLICIES: 'app.policies',
    ADDPOLICIESLISTS: 'app.add_policy_lists',
    EDITPOLICIESLISTS: 'app.edit_policy_lists',
    VIEWPOLICIESLISTS: 'app.view_policy_lists',
    ENCOUNTERS: 'app.encounters',
    ENCOUNTER: 'app.encounter',

    ENCOUNTERS_CLAIMS: 'app.encounters-claims',

    POLICYMANAGEMENT: 'app.PolicyManagement',

    ADDCLAIM: 'app.addClaim',
    EDITCLAIM: 'app.editClaim',
    VIEWCLAIM: 'app.viewClaim',


    // Start Reports 
    REPORT: 'app.Report',
    COLLECTIONREPORT: 'app.collectionReport',
    AGINGREPORT: 'app.agingReport',
    DICTIONARIES: 'app.dictionaries',
    BENEFITS: 'app.benefits',
    ADDBENEFIT: 'app.add_benefit',
    EDITBENEFIT: 'app.edit_benefit',
    VIEWBENEFIT: 'app.view_benefit',
    ADD_NEW_BENEFIT: 'app.add_new_benefit',

    PATIENT: 'app.patient',
    CREATEPATIENT: 'app.createPatient',
    EDITPATIENT: 'app.editPatient',
    PATIENT_ID: 'app.patient_ID',
    ADD_NEW_PATIENT: 'app.add_new_patient',
    VIEWPATIENT: 'app.view_patient',
    SUMMARY: 'app.dashboard_summary',
    REJECTION: 'app.dashboard_rejection',
    REJECTED: 'app.rejected',
    PENDING: 'app.pending',
    SUBMITTED: 'app.submitted',
    SUBMITTE: 'app.submitte',
    FOLLOWUPS: 'app.followUps',
    ERROR: 'app.error',
    SOME_THING_WENT_WRONG: 'app.something_went_wrong_message',
    error: 'app.close',
    CHANGE_STATUS_CONFIRMATION_MSG: 'app.change_status_confirmation_msg',
    CONFIRMATION: 'app.confirmation',
    CLOSE: 'app.close',
    EMPLOYEESPROCEDURES: 'app.employees_procedures',
    VIEWEMPLOYEEPROCEDURE: 'app.view_employees_procedures',
    JOBSPROCEDURES: 'app.jobs_procedures',
    SALARYRALLIES: 'app.salary_rallies',
    VIEWSALARYRALLIES: 'app.view_salary_rallies',

    VACATIONS: 'app.vacations',
    VIEWVACATIONS: 'app.view_vacations',

    QUALIFICATIONS: 'app.qualifications',
    VIEWQUALIFICATIONS: 'app.view_qualifications',
    QUARTRY: 'app.quarter',
    MONTHLY: 'app.monthly',
    YEARLY: 'app.yearly',
    WEEKLY: 'app.weekly',
    REVIEWS: 'app.reviews',
    VIEWREVIEWS: 'app.view_reviews',

    PERSONALINFORMATION: 'app.personal_information',
    JOBDATA: 'app.job_data',
    VACATIONDATA: 'app.vacation_data',
    PERFORMANCEDATA: 'app.performance_data',
    RATINGDATA: 'app.rating_data',
    SALARYDATA: 'app.salary_data',
    LOCATIONDATA: 'app.location_data',

    EMPLOYEESREPORTS: 'app.employees_reports',
    PERFORMANCEREPORTS: 'app.performance_reports',
    REQUESTS_MANAGEMENT: 'app.requests_management',
    CATEGORIES_MATCHING_MANAGEMENT: 'app.categories_matching_management',
    REPORTS_MANAGEMENT: 'app.reports_management',
    RESEND: 'app.resend',
    COMMITMENT_RATE: 'app.commitment_rate',
    PROCEDURES_TYPES: 'app.procedures_types',
    EMPLOYEE_TYPE: 'app.employee_type',
    SYSTEM_PROPERTIES_TITLE: 'app.systemPropertiesTitle',
    TITLE: 'app.title',
    MATCHING_DATA: 'app.matching_data',
    VIEWJOBPROCEDURE: 'app.viewJobProcedure',
    AR_FIRSTNAME: 'app.ar_firstname',
    AR_SECONDNAME: 'app.ar_secondname',
    AR_thirdAME: 'app.ar_thirdame',
    AR_FourthNAME: 'app.ar_fourthname',
    EN_FIRSTNAME: 'app.en_firstname',
    EN_SECONDNAME: 'app.en_secondname',
    EN_thirdAME: 'app.ar_thirdame',
    EN_FourthNAME: 'app.en_fourthname',
    //Start TimeSheet
    CONFIGURATIONS: 'app.configurations',
    ORGANIZATION_STRUCTURE: 'app.organization_structure',
    REVENUE_STREAMS: 'app.revenue_streams',
    PROJECTS: 'app.projects',
    PROJECT_NUMBER: 'app.project',
    CHARGE_CODES: 'app.charge_codes',
    ADD_NEW_CHARGE_CODES: 'app.add_new_charge_codes',
    MATERIAL_STATUS: 'app.material_status',
    ADD_CHARGE_CODE_CONF: 'app.add_charge_code_conf',
    SINGLE: 'app.single',
    MARRIED: 'app.married',
    DIVORCED: 'app.divorced',
    WIDOWER: 'app.widower',
    UNSELECT: 'app.unselect',
    // Start Organization Structure
    DEPARTMENT: 'app.department',
    EMPLOYEE_DEPARTMENT: 'app.employee_department',
    UNITS: 'app.units',
    SECTION: 'app.section',
    ACTIVE: 'status.active',
    IN_ACTIVE: 'status.inActive',
    EMPLOYEES: 'app.employees',
    EXAMPLE: 'app.examples',
    EMPLOYEE_TIME_SHEET: 'app.employee_time_sheet',
    EMPLOYEE_TIMESHEET: 'app.employee_timesheet',
    MY_TIMESHEET: 'app.my_timesheet',
    DETAILED_REPORT: 'app.Detailed_Report',
    AGREE: 'app.agree',
    DISAGREE: 'app.disagree',
    WUTHDRAWAL: 'app.wuthdrawal',
    TODAY: 'app.today',
    NEXT: 'app.next',
    PREVIOUS: 'app.previous',
    UPDATE: 'app.update',
    SUMMARY_REPORT: 'app.summary_report',
    GENERAL_REPORT: 'app.general_report',
    COST_REPORT: 'app.cost_report',
    COPY_WEEK: 'app.copy_week',
    DELETED_DEILY_WORK_ROW: 'app.deleted_deily_work_row',
    DRAFT: 'app.draft',
    NO_ACTIONS: 'app.no_actions',
    APPROVED: 'app.approved',
    APPROVE: 'app.approve',
    CUSTOMER: 'app.customer',
    STARTDATE: 'app.start_date',
    ENDDATE: 'app.end_date',
    CREATED_SUCCESS: 'app.created_success',
    DELETED_SUCCESS: 'app.deleted_success',
    UPDATED_SUCCESS: 'app.updated_success',
    DELETE_CONFIRMATION_MESSAGE: 'app.delete_confirmation_message',
    LOGOUT: 'app.logout',
    OOPS: 'app.oops',
    HOME_PAGE: 'app.home_page',
    MSG404: 'app.msg_404',
    SOMETHING_WENT_WRONG_MESSAGE: 'app.something_went_wrong_message',
    ADMINISTRATIVE: 'app.administrative',
    BILLABLE: 'app.billable',
    DEVELOPMENT: 'app.development',
    OTHERS: 'app.others',
    CHARGECODE_ALREDY_EXIST: 'app.chargecode_alredy_exist',
    REJECT_REASON: 'app.reject_reason',
    CHANGE_PASSWORD: 'app.change_password',
    OLD_PASSWORD: 'app.old_password',
    NEW_PASSWORD: 'app.new_password',
    CONFIRM_PASSWORD: 'app.confirm_password',//confirm password,
    FORGET_PASSWORD: 'app.forget_password',//forget password
    SEND: 'app.send',
    RESET_PASSWORD: 'app.reset_password',
    RESET_PASSWORD_MESSAGE: 'app.reset_password_message',
    HOURS_VALIDATIONS_MESSAGE: 'app.hours_validations_message',
    NUMBER: 'app.number',
    DIRECTREPORTEES: 'app.directreportees',
    UNLOCK: 'app.unlock',
    CONFIGURATION_HOUR: 'app.configuration_hour',
    HOURS: 'app.hours'

};

const Resources = {
    ...AppResources
};

export default Resources;

export {
    AppResources
};

