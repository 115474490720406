import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetEDTDateFromDateTime } from "../../../Utils/DateUtils";

const summaryReport = createSlice({
    name: "summaryReport",
    initialState: {
        ListOfReports: {},
        keys: [],
        ReportDetails: {},
        searchCriteria: {
            startDate: null,
            endDate: null,
            organizationId: null,
            employeesIdsList: [],
            status: []
        },
        showError: false,
        error: null
    },
    reducers: {
        reportsRecived: (summaryReport: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {
                summaryReport.ListOfReports[item.id] = item;
                summaryReport.keys = [...summaryReport.keys, item.id]
            });

            summaryReport.ReportDetails = {};
        },
        setReport: (summaryReport: any, { payload }: PayloadAction<any>) => {
            summaryReport.reportDetails = payload.data;
        },
        setError: (summaryReport: any, { payload }: PayloadAction<any>) => {
            summaryReport.error = payload.response;
            summaryReport.showError = true;
        },

        setSearchCriteria: (summaryReport: any, { payload }: PayloadAction<any>) => {
            const { startDate, endDate, employeesIdsList, organizationId, status } = payload.body;
            summaryReport.searchCriteria = {
                startDate: GetEDTDateFromDateTime(startDate),
                endDate: GetEDTDateFromDateTime(endDate),
                employeesIdsList: employeesIdsList,
                organizationId: organizationId,
                status: status
            };
        },
        resetSearchCriteria: (summaryReport: any) => {
            summaryReport.searchCriteria = {
                startDate: null,
                endDate: null,
                employeesIdsList: [],
                organizationId: null,
                status: []
            };
        }
    }
});

export const {
    reportsRecived,
    setReport,
    setError,
    setSearchCriteria,
    resetSearchCriteria
} = summaryReport.actions;

export default summaryReport.reducer;

//#region :: Selectors
export const getReportsData = (state: any) => {
    return state.summaryReport.ListOfReports;
};

export const getSearchCriteria = (state: any) => {
    return state.summaryReport.searchCriteria;
};
export const getError = (state: any) => {
    return state.summaryReport.error;
};

export const getReportDetails = (state: any) => {
    return state.summaryReport.ReportDetails;
};
export const getShowError = (state: any) => {
    return state.summaryReport.showError;
};

export const summaryReportSelectors = {
    getSearchCriteria,
    getReportDetails,
    getReportsData,
    getError,
    getShowError,
};
//#endregion