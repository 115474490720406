export class StorageService {

    private static isEncripted: boolean = false;


    public static getItem(key: STORAGE_KEY) {
        let data: any = typeof window !== 'undefined' ? sessionStorage.getItem(key) : null;

        if (!data) {
            data = typeof window !== 'undefined' ? localStorage.getItem(key) : null;
        }

        if (!data) return null;

        try {
            return JSON.parse(data);
        }
        catch (err) {
            return data;
        }
    }


    public static setItem(key: STORAGE_KEY, value?: any, isLocalStorage: boolean = false) {
        let stringify = typeof value !== 'string' ? JSON.stringify(value) : value;
        if (isLocalStorage) {
            localStorage.setItem(key, stringify);
        } else {
            sessionStorage.setItem(key, stringify);
        }
    };


    public static removeItem(key: STORAGE_KEY) {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
    };

    public static clearStorage() {
        localStorage.clear();
        sessionStorage.clear();
    };

}


export enum STORAGE_KEY {
    ACCESS_TOKEN = 'access_token',
    REFRESH_TOKEN = 'refresh_token',
    CUURENT_USERNAME = 'current_userName',
    CURRENT_USER = 'current_user'
}