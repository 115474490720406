
import { createSlice } from '@reduxjs/toolkit';
import { getCookie, setCookie } from '../../Utils/Cookies';
import { setItem } from '../../Utils/LocalStorage';
import CookiesConstants from '../../Config/Settings/Cookies';
import LocalStorageConstants from '../../Config/Settings/LocalStorage';

const auth = createSlice({
    name: 'auth',
    initialState: {
        isLoggedIn: getCookie(CookiesConstants.AUTH.IS_LOGGED_IN),
        currentUser: null,
        error: null,
        isLoading: false,
    },
    reducers: {
        loginBegan: (auth, action) => {
            auth.isLoading = true;
        },
        loginSucceeded: (auth, { payload }) => {
            const data = payload.body;
            setItem(LocalStorageConstants.CURRENT_USER, data);
            setCookie(CookiesConstants.AUTH.IS_LOGGED_IN, true);
            auth.currentUser = data;
            auth.isLoggedIn = true;
            auth.isLoading = false;
        },
        loginFailed: (auth, { payload }) => {
            auth.error = payload;
            auth.isLoading = false;
        },
        logoutBegan: (auth, action) => {
            auth.isLoading = true;
        },
        logoutSucceeded: (auth, { payload }) => {
            auth.isLoggedIn = payload;
            auth.isLoading = false;
        },
        logoutFailed: (auth, { payload }) => {
            auth.error = payload;
            auth.isLoading = false;
        },

    }
});

export const {
    loginBegan, loginSucceeded, loginFailed, logoutBegan, logoutFailed, logoutSucceeded
} = auth.actions;

export default auth.reducer;

//#region :: Selectors

export const getAuth = (state: any) => {
    return state.auth;
}

export const isLoggedIn = (state: any) => {
    return state.auth.isLoggedIn;
};

export const AuthSelectors = {
    getAuth,
    isLoggedIn
};

//#endregion