import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import propTypes from 'prop-types';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import useTranslation from '../../../Hooks/Shared/useTranslation';
import { theme as MainThemeVariables } from '../../../Config/Theme/ThemeVariables';
import './style.scss';
import { regexType } from '../../../Config/Settings/Constants/Validations';



type ListItemsProps = {
    itemTitle?: any;
    path?: any;
    iconSrc?: any;
    badgeContent?: any;
    collapseMenu?: any;
    collapseSub?: any;
    menuSubItems?: any;
    onClick?: any;
};

const ListItems = ({ itemTitle, path, iconSrc, badgeContent, collapseMenu, collapseSub, menuSubItems, onClick }: ListItemsProps) => {

    //#region :: State
    const { T, Resources }: any = useTranslation();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [active, setActive] = useState('');
    const location = useLocation();

    const getRoutParts = () => {
        const regex = regexType.NUMBER;
        const baseRoute = process.env.REACT_APP_BASE_URL || '/';
        const breadPath = location.pathname.replace(baseRoute, '');
        let pathList = breadPath.split('/');
        let index;

        pathList.map((item) => {
            let checkNumber = regex.test(item);
            if (checkNumber) {
                index = pathList.indexOf(item);
            }
        });

        if (index !== undefined) {
            pathList.splice(index, 1)
        }
        if (pathList[pathList.length - 1] !== undefined && pathList[pathList.length - 1] !== "") {
            const name = pathList[pathList.length - 1].toString().toUpperCase() as string;
            const label = T(Resources.AppResources[name])
            return label;
        }
        else {
            return 'MY_TIMESHEET';
        }

    };

    useEffect(() => {
        const routeParts = getRoutParts();
        setActive(routeParts)
    });
    //#endregion

    //#region :: Handlers
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //#endregion

    return (
        <>
            {/* Menu Items */}
            <StyledListItem className={`list-item ${collapseMenu && active === itemTitle ? "list-item-active" : ""}`} onClick={() => onClick !== undefined ? onClick() : null}>
                {path !== undefined
                    ? <>
                        <StyledContainerLink to={path}>
                            {!collapseMenu
                                ? <Tooltip title={itemTitle} placement="right">
                                    <StyledNavMenuIcon
                                        to={path}
                                        activeStyle={{ backgroundColor: MainThemeVariables['light-blue-color'] }}>
                                        {iconSrc}
                                    </StyledNavMenuIcon>
                                </Tooltip>
                                : <StyledNavMenuIcon
                                    to={path}
                                    activeStyle={{ backgroundColor: MainThemeVariables['light-blue-color'] }}>
                                    {iconSrc}
                                </StyledNavMenuIcon>}
                            {collapseMenu &&
                                <StyledLink to={path}>
                                    <StyledListItemText primary={itemTitle} />
                                </StyledLink>}
                        </StyledContainerLink>
                    </>
                    : <>
                        <StyledSpanMenuIcon
                            onMouseOver={!collapseMenu ? handleClick : null}>
                            <StyledMenuBadge
                                color="secondary"
                                badgeContent={badgeContent}>
                            </StyledMenuBadge>
                            {iconSrc}
                        </StyledSpanMenuIcon>
                        {collapseMenu && <StyledListItemText primary={itemTitle} />}
                    </>}
                {collapseSub !== undefined ?
                    collapseSub
                        ? <StyledExpandLess />
                        : <StyledExpandMore />
                    : null}
            </StyledListItem>

            {/* Sub Menu Shown When Menu Is Closed */}
            {
                menuSubItems !== undefined ?
                    <>
                        <Collapse
                            in={collapseSub}
                            timeout="auto"
                            unmountOnExit>
                            <List
                                disablePadding
                                component="div">
                                {menuSubItems.map((item: any) => (
                                    <StyledSubItem
                                        key={item.NAME}
                                        to={item.PATH}>
                                        <ListItem button>
                                            <ListItemIcon></ListItemIcon>
                                            <StyledListItemText primary={T(Resources.AppResources[item.NAME])} />
                                        </ListItem>
                                    </StyledSubItem>
                                ))}
                            </List>
                        </Collapse>
                        <StyledMenu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            MenuListProps={{ onMouseLeave: handleClose }}>
                            <StyledDivMenuItem>
                                {menuSubItems.map((item: any) => (
                                    <StyledPopupSubItem to={item.PATH}>
                                        <MenuItem
                                            key={item.NAME}
                                            onClick={handleClose}>
                                            {T(Resources.AppResources[item.NAME])}
                                        </MenuItem>
                                    </StyledPopupSubItem>
                                ))}
                            </StyledDivMenuItem>
                        </StyledMenu>
                    </>
                    : null
            }
        </>
    );
};

//#region :: Styled Components

const StyledListItem: any = styled(ListItem)(({ theme }) => ({
    fontSize: '0.8rem!important',
    lineHeight: '2.5'
}));

const StyledMenu: any = styled(Menu)(({ theme }) => ({
    "& .MuiPaper-root": {
        backgroundColor: theme.palette.primary.main,
        marginTop: '-60px',
        [theme.direction === 'rtl' ? 'marginLeft' : 'marginLeft']: theme.direction === 'rtl' ? '-63px!important' : '63px!important'
    }
}));

const StyledNavMenuIcon: any = styled(NavLink)(({ theme }) => ({
    borderRadius: '50%',
    [theme.direction === 'ltr' ? 'marginRight' : 'marginLeft']: 20,
    // backgroundColor: theme.palette.info.dark,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    display: 'flex',
    color: '#fff',
    width: '40px',
    height: '40px',
    minWidth: '40px',

    '&:hover': {
        backgroundColor: 'rgb(91 144 225)!important',
        borderRadius: '50%',
        [theme.direction === 'ltr' ? 'marginRight' : 'marginLeft']: 20,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        display: 'flex',
        color: '#fff',
        width: '40px',
        height: '40px',
        minWidth: '40px',
    }
}));

const StyledContainerLink: any = styled(NavLink)(({ theme }) => ({
    "& .MuiTypography-displayBlock": {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    textDecoration: 'none',
    color: theme.status.white,
    display: 'flex'
}));

const StyledSpanMenuIcon: any = styled.span(({ theme }) => ({
    borderRadius: '50%',
    padding: 13,
    [theme.direction === 'ltr' ? 'marginRight' : 'marginLeft']: 20,
    backgroundColor: theme.palette.primary.light,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    display: 'flex !important',
    width: '40px',
    height: '40px',
    minWidth: '40px'
}));

const StyledLink: any = styled(NavLink)(({ theme }) => ({
    "& .MuiTypography-displayBlock": {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '140px',
        textOverflow: 'ellipsis'
    },
    textDecoration: 'none',
    color: theme.status.white,
}));

const StyledMenuBadge: any = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        [theme.direction === 'rtl' ? 'right' : 'left']: 'auto'
    },
    bottom: 22
}));

const StyledListItemText: any = styled(ListItemText)(({ theme }) => ({
    "& .MuiTypography-displayBlock": {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

const StyledExpandLess: any = styled(ExpandLess)(({ theme }) => ({
    cursor: 'pointer'
}));

const StyledExpandMore: any = styled(ExpandMore)(({ theme }) => ({
    cursor: 'pointer'
}));

const StyledSubItem: any = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.status.white,
    whiteSpace: 'nowrap',
    width: '80%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

const StyledPopupSubItem: any = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.status.white
}));

const StyledDivMenuItem: any = styled.div(({ theme }) => ({
    color: theme.status.white,
    backgroundColor: theme.palette.primary.main,
    textDecoration: 'none',
}));
//#endregion

//#region :: Validation PropTypes
ListItems.propTypes = {
    itemTitle: propTypes.string,
    path: propTypes.any,
    iconSrc: propTypes.element,
    badgeContent: propTypes.number,
    collapseMenu: propTypes.bool,
    collapseSub: propTypes.bool,
    menuSubItems: propTypes.array,
    onClick: propTypes.func
};
//#endregion

export default ListItems;