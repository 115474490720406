import { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { GlobalServices } from '../API/GlobalServices';
import Locales, { Languages } from './Locales';

//Provider:
const LocaleProvider = ({ children, locale = Locales.ENGLISH }: any) => {
    const [localResources, setLocalResources] = useState<any>({});

    const getLocalResources = async () => {
        if (localResources[locale]) return;
        try {
            const response = await GlobalServices.GetLocalResources(Languages[locale]);
            if (response) {
                let lang = {};
                response != undefined && response.body && response.body.map((item: any) => {
                    lang = {
                        ...lang,
                        [item.key]: item.value
                    };
                });

                setLocalResources({
                    ...localResources,
                    [locale]: { ...lang }
                });
            }
        }
        catch (error: any) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (localResources[locale]) return;
        getLocalResources();
    }, [locale]);

    return <IntlProvider
        locale={locale}
        textComponent={Fragment}
        messages={localResources[locale]}
        onError={(error: any) => {
        }}>
        {children}
    </IntlProvider>
};

export default LocaleProvider;

//Translate:
export const Format = (id: any, value = {}) => <FormattedMessage id={id} values={{ ...value }} />;