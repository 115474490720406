//import axios from 'axios';
import * as actions from '../Actions/API';
import { changeFullLoading } from '../Reducers/UI';
import { axios } from '../../Services/API/WebServiceClient';
import APIConstants from '../../Config/Settings/API';

let requestsCancelations: Array<any> = [];

const API = ({ dispatch }: any) => (next: any) => async (action: any) => {
    if (action.type !== actions.apiCallBegan.type) return next(action);

    next(action);

    const { url, method, data, onSuccess, onError, args } = action.payload;
    dispatch(changeFullLoading({ spin: true }));

    try {
        //Cancel Repetitive Requests
        let currentUrlRequests = requestsCancelations.filter(x => x.url === url);
        if (currentUrlRequests) {
            currentUrlRequests.map(request => request.cancelRequest());
            requestsCancelations = requestsCancelations.filter(x => x.url !== url);
        }

        const response = await axios.request({
            baseURL: APIConstants.BASE_URL,
            headers: {
                // 'Authorization': `Bearer  ${getCookie('token')}`,
            },
            url,
            method,
            data,
            cancelToken: new axios.CancelToken(c => requestsCancelations.push({ url, cancelRequest: c }))
        });

        requestsCancelations = requestsCancelations.filter(x => x.url !== url);

        //General:
        dispatch(actions.apiCallSuccess());

        //Specifc:
        if (onSuccess) {
            dispatch(changeFullLoading({ spin: false }));
            dispatch({ type: onSuccess, payload: response.data, args });
        }

    }
    catch (error) {
        if (axios.isCancel(error)) {
            return;
        }
        console.log('CALL_API_ERROR', error);
        //General:
        dispatch(actions.apiCallFailed());

        //Specifc:
        if (onError) {
            dispatch(changeFullLoading({ spin: false }));
            dispatch({ type: onError, payload: error, args });
        }
    }
};

export default API;