import { createSlice } from '@reduxjs/toolkit';
import CookiesConstants from '../../Config/Settings/Cookies';
import Locales, { LocaleDirection } from '../../Services/Internationalization/Locales';
import { getCookie } from '../../Utils/Cookies';

const currentLang = getCookie(CookiesConstants.SETTINGS.LANG) || Locales.ARABIC;

const ui = createSlice({
    name: 'ui',
    initialState: {
        language: currentLang,
        direction: LocaleDirection[currentLang],
        themeMode: 'light',
        loading: false,
        jsonLoading: false,
        menuIsOpened: true
    },
    reducers: {
        languageChanged: (ui, { payload }) => {
            ui.language = payload.language;
            ui.direction = LocaleDirection[payload.language];
            window.location.reload();
        },
        themeModeChanged: (ui, { payload }) => {
            ui.themeMode = payload.themeMode;
        },
        changeFullLoading: (ui, { payload }) => {
            ui.loading = payload.spin;
        },
        changeJsonLoading: (ui, { payload }) => {
            ui.jsonLoading = payload.spin;
        },
        menuOpenStatus: (ui, { payload }) => {
            console.log("payload", payload.menuIsOpened)

            ui.menuIsOpened = payload.menuIsOpened;
        },
    }
});

export const {
    languageChanged,
    themeModeChanged,
    changeFullLoading,
    changeJsonLoading,
    menuOpenStatus
} = ui.actions;

export default ui.reducer;

//#region :: Selectors

export const getUI = (state: any) => {
    return state.ui;
}

export const getMenuStatus = (state: any) => {
    return state.ui.menuIsOpened;
}

export const getUIDirection = (state: any) => {
    return state.ui.direction;
}

export const getUILanguage = (state: any) => {
    return state.ui.language;
}

export const getUIThemeMode = (state: any) => {
    return state.ui.themeMode;
}

export const getFullLoading = (state: any) => {
    return state.ui.loading;
}

export const getJsonLoading = (state: any) => {
    return state.ui.jsonLoading;
}

export const UISelectors = {
    getUI,
    getUIDirection,
    getUILanguage,
    getUIThemeMode,
    getFullLoading,
    getJsonLoading,
    getMenuStatus
};

//#endregion