import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import BreadCrumb from '../BreadCrumb';

export interface PageHeaderProps {
    title?: any;
    border?: any;
    breadCrumbs?: any;
};

const PageHeader = ({ title, border = false, breadCrumbs }: PageHeaderProps) => {
    return (
        <>
            {border
                ? <StyledBorderHeader>
                    {title && title}
                </StyledBorderHeader>
                : <StyledTitle>
                    {title && title}
                </StyledTitle>}
            {breadCrumbs && <BreadCrumb />}
        </>
    )
};

//#region :: Styled Components
const StyledTitle: any = styled.div`
font-weight:bold;
`;

const StyledBorderHeader: any = styled.div`
    border: 1px solid #e7e7e7;
    padding: 15px;
    background: ${({ theme }) => theme && theme.palette.primary.Gray93};
    border-radius: 4px;
`;
//#endregion

//#region :: Validation PropTypes
PageHeader.propTypes = {
    title: propTypes.string,
    breadCrumbs: propTypes.bool
};
//#endregion

export default PageHeader;