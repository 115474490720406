import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const chargeCodesManagement = createSlice({
  name: "chargeCodesManagement",
  initialState: {
    chargeCodes: {},
    keys: [],
    chargeCodesDetails: {},
    searchCriteria: {
      OrganizationId: null,
      RevenueSreamId: null,
      ProjectId: null,
      // Status: null
      IsActive: null
    },
    showError: false,
    error: null,
  },
  reducers: {
    chargeCodesRecived: (chargeCodesManagement: any, { payload }: PayloadAction<any>) => {
      const data = payload.body;
      data.map((item: any) => {
        chargeCodesManagement.chargeCodes[item.id] = item;
        chargeCodesManagement.keys = [...chargeCodesManagement.keys, item.id];
      });
      chargeCodesManagement.chargeCodesDetails = {};
    },

    chargeCodeUpdated: (chargeCodesManagement: any, { payload }: PayloadAction<any>) => {
      const data = payload.body;

      chargeCodesManagement.chargeCodes[data.id] = { ...chargeCodesManagement.chargeCodes[data.id], isActive: !data.isActive };
      chargeCodesManagement.keys = [...chargeCodesManagement.keys, data.id];
    },

    deletechargeCodesById: (chargeCodesManagement: any, { payload }: PayloadAction<any>) => {
      const data = payload.body;
      const chargeCodesId = payload.chargeCodesId;
      const filteredkeys = chargeCodesManagement.keys.filter((x: any) => x != chargeCodesId);

      chargeCodesManagement.keys = filteredkeys;
    },
    setChargeCode: (chargeCodesManagement: any, { payload }: PayloadAction<any>) => {
      chargeCodesManagement.chargeCodesDetails = payload.data;
    },

    setSearchCriteria: (chargeCodesManagement, { payload }) => {
      const { OrganizationId, RevenueSreamId, ProjectId, Status, IsActive } = payload.body;
      chargeCodesManagement.searchCriteria = {
        OrganizationId: OrganizationId,
        RevenueSreamId: RevenueSreamId,
        ProjectId: ProjectId,
        // Status: Status
        IsActive: IsActive
      };
    },

    resetSearchCriteria: (chargeCodesManagement: any) => {
      chargeCodesManagement.searchCriteria = {
        OrganizationId: null,
        RevenueSreamId: null,
        ProjectId: null,
        IsActive: null
      };
    },

    setError: (chargeCodesManagement: any, { payload }: PayloadAction<any>) => {
      chargeCodesManagement.error = payload.response;
      chargeCodesManagement.showError = true;
    },
  },
});

export const {
  chargeCodesRecived,
  setChargeCode,
  setSearchCriteria,
  resetSearchCriteria,
  deletechargeCodesById,
  setError,
} = chargeCodesManagement.actions;

export default chargeCodesManagement.reducer;

//#region :: Selectors
export const getchargeCodesData = (state: any) => {
  return state.chargeCodesManagement.chargeCodes;
};

export const getChargeCode = (state: any) => {
  return state.chargeCodesManagement.chargeCodeDetails;
};

export const getchargeCodesKeys = (state: any) => {
  return state.chargeCodesManagement.keys;
};

export const getSearchCriteria = (state: any) => {
  return state.chargeCodesManagement.searchCriteria;
};

export const getShowError = (state: any) => {
  return state.chargeCodesManagement.showError;
};

export const getError = (state: any) => {
  return state.chargeCodesManagement.error;
};

export const chargeCodesSelectors = {
  getchargeCodesData,
  getchargeCodesKeys,
  getSearchCriteria,
  getShowError,
  getError,
};
//#endregion
