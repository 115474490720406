import { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import CookiesConstants from '../../Config/Settings/Cookies';
import Layout from '../../Layout/Master';
import { GlobalService } from '../../Services/global.service';
import { getCookie } from '../../Utils/Cookies';
import { isMobile } from '../../Utils/Validation';

//#region :: Lazy Imports

//#region :: Lazy Imports
const Approvals = lazy(() => import('../../Pages/Reports/Approvals'));
const GeneralReport = lazy(() => import('../../Pages/Reports/GeneralReport'));
const SummaryReport = lazy(() => import('../../Pages/Reports/SummaryReport'));
const CostReport = lazy(() => import('../../Pages/Reports/CostReport'));

const MyTimesheet = lazy(() => import('../../Pages/MyTimesheet'));
const MyTimesheetMobile = lazy(() => import('../../Pages/MyTimesheet/index.mobile'));

const NotFound = lazy(() => import("../../Pages/Errors/NotFound"));
const UnauthorizedError = lazy(() => import("../../Pages/Errors/UnauthorizedError"));
const ServiceUnavailable = lazy(() => import("../../Pages/Errors/ServiceUnavailable"));
const ForbiddenError = lazy(() => import("../../Pages/Errors/ForbiddenError"));
const ChangePassword = lazy(() => import('../../Pages/Auth/ChangePasswerd'))

const OrganizationStructure = lazy(() => import('../../Pages/ConfigurationStructure/OrganizationStructure'));
const ChargeCodes = lazy(() => import('../../Pages/ConfigurationStructure/ChargeCodes'));
const ConfigurationHour = lazy(() => import('../../Pages/ConfigurationHour/index'));

const Projects = lazy(() => import('../../Pages/ConfigurationStructure/Projects'));
const RevenueStreams = lazy(() => import('../../Pages/ConfigurationStructure/RevenueStreams'));
const Employee = lazy(() => import('../../Pages/Employees'));
const EmployeeTimesheet = lazy(() => import('../../Pages/EmployeeTimesheet'));
//#endregion

const Admin = () => {

  const { path } = useRouteMatch();

  const isMobileDevice = isMobile();
  const renderMyTimeSheet = isMobileDevice ? MyTimesheetMobile : MyTimesheet;

  //#region :: Roles Check
  const state = useSelector(state => state);
  const auth = getCookie(CookiesConstants.AUTH.ROLE);

  const currentUser = GlobalService.AppUser;
  const SystemAdmin = currentUser && currentUser?.isSystemAdmin;
  const Manager = currentUser && currentUser?.isManager;

  //#endregion

  return (
    // <Switch>
    //   {SystemAdmin && <SystemAdminRoutes />}
    //   {Manager && <ManagerRoutes />}
    //   {Employee && <EmployeeRoutes />}
    //   {SystemAdminAndManager && <AdminManagerRoutes />}
    // </Switch>

    <>
      {SystemAdmin || Manager ? <>
        <Route exact path={`${path}/General_Report`} component={GeneralReport} />
        <Route exact path={`${path}/Summary_Report`} component={SummaryReport} />
        <Route exact path={`${path}/Cost_Report`} component={CostReport} />
        <Route exact path={`${path}/employee_Timesheet`} component={EmployeeTimesheet} />
      </> : null}

      {SystemAdmin ? <>
        <Route exact path={`${path}/organization_structure`} component={OrganizationStructure} />
        <Route exact path={`${path}/charge_codes`} component={ChargeCodes} />
        <Route exact path={`${path}/projects`} component={Projects} />
        <Route exact path={`${path}/revenue_streams`} component={RevenueStreams} />
        <Route exact path={`${path}/employees`} component={Employee} />
        <Route exact path={`${path}/configuration_hour`} component={ConfigurationHour} />
      </> : null}

      {Manager ? <>
        <Route exact path={`${path}/Approvals`} component={Approvals} />
      </> : null}


      <Route exact path={`${path}/401`} component={UnauthorizedError} />
      <Route exact path={`${path}/403`} component={ForbiddenError} />
      <Route exact path={`${path}/503`} component={ServiceUnavailable} />
      <Route exact path={`${path}/404`} component={NotFound} />


      <Route exact path={`${path}/`} component={renderMyTimeSheet} />
      <Route exact path={`${path}/home`} component={renderMyTimeSheet} />
      <Route exact path={`${path}/change_password`} component={ChangePassword} />
      <Route exact path={`${path}/my_timesheet`} component={renderMyTimeSheet} />


      {/* <Route component={NotFound} /> */}

    </>
  );
};

export default Layout(Admin);