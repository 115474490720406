import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const Container = ({ children, margin = 15, minHeight }: any) => {
    return (
        <StyledDiv margin={margin} minHeight={minHeight}>
            {children}
        </StyledDiv>
    );
}

//#region :: Styled Components
const StyledDiv: any = styled.div`
margin:${({ margin }: any) => margin && margin + 'px'};
min-height:${({ minHeight }: any) => minHeight && minHeight + 'vh'};
margin-bottom: 0px !important;
`;
//#endregion

//#region :: Validation PropTypes
Container.propTypes = {
    children: propTypes.any,
    margin: propTypes.number
};
//#endregion

export default Container;