const Locales: any = {
    ENGLISH: 'en-us',
    ARABIC: 'ar-sa'
};

const LocalesEnum = {
    ARABIC: 1,
    ENGLISH: 2
};

const LocaleDirection = {
    [Locales.ENGLISH]: 'ltr',
    [Locales.ARABIC]: 'rtl'
};

export const LanguagesShortName = {
    ENGLISH: 'en',
    ARABIC: 'ar'
};

const Languages: any = {
    [Locales.ARABIC]: LocalesEnum.ARABIC,
    [Locales.ENGLISH]: LocalesEnum.ENGLISH
};

export { LocaleDirection, LocalesEnum, Languages };

export default Locales;